.c-nav {
  display: none;
  position: fixed;
  z-index: $z-nav;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  padding: 15rem 0 2rem;
  overflow: auto;
  background: rgba($color-white, .96);

  @include bp-medium {
    align-items: center;
    justify-content: center;
    padding: 15rem 2rem 5rem;
    text-align: center;
  }

  &.is-open {
    display: block;

    @include bp-medium {
      display: flex;
    }
  }
}

.c-nav__list {
  width: 100%;
  margin: 0;
  list-style: none;

  @include bp-medium {
    width: auto;
  }
}

.c-nav__list-item {
  text-transform: uppercase;
  font-family: $font-default;
  font-weight: 500;
  font-size: 1.8rem;
  letter-spacing: 3px;
  border: 1px solid rgba($color-pale-blue, .3);
  border-width: 1px 0 0 0;
  margin: 0;
  transition: opacity .25s;
  opacity: .7;

  @include bp-medium {
    font-family: $font-freight;
    font-size: 3.2rem;
    letter-spacing: 5.3px;
    margin-bottom: 3rem;
    border: none;
  }

  &:last-child {
    border-width: 1px 0;
  }

  &:hover {
    opacity: 1;
  }
}

.c-nav__list-item-link {
  display: block;
  text-decoration: none;
  padding: 2rem $container-padding;

  @include bp-small {
    padding: 2rem $container-padding-small;
  }

  @include bp-medium {
    padding: 0;
  }
}

.c-nav__sublist {
  display: none;
  margin: 0;
  list-style: none;

  @include bp-medium {
    display: block;
  }
}

.c-nav__sublist-item {
  display: block;
  text-decoration: none;
  transition: opacity .25s;
  opacity: .7;

  &:hover {
    opacity: 1;
  }
}

.c-nav__sublist-item-link {
  display: block;
  text-decoration: none;
  color: $color-pale-blue;

  &:hover {
    color: $color-pale-blue;
  }
}
