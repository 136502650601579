.c-intro {
  max-width: 950px;

  @include bp-medium {
    margin: 2rem auto 10rem;
    text-align: center;
  }
}

.c-intro__text {
  max-width: 770px;
  margin: 0 auto;
}

.c-intro__heading {
  font-family: $font-freight;
  font-size: 2.5rem;
  text-transform: uppercase;
  letter-spacing: 1px;

  @include bp-medium {
    font-size: 3.2rem;
    letter-spacing: 4px;
  }
}
