.o-link-buttonized {
  text-transform: uppercase;
  font-weight: 600;
  text-decoration: none;
  display: inline-block;
  height: 50px;
  line-height: 50px;
  border: 1px solid $color-pale-blue;
  border-radius: 25px;
  padding: 0 25px;
  color: $color-pale-blue;
  transition: background-color 250ms, color 250ms;

  &:hover {
    background-color: $color-pale-blue;
    color: $color-white;
  }
}
