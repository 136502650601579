.c-covers-carousel {
  position: relative;
  flex: 1 0 auto;
  margin: 0 -26px;
}


.c-covers-carousel__photo {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    display: block;
    height: 30px;
    top: calc(100% - 2px);
    left: 10px;
    right: 10px;
    border-radius: 50%;
    background: radial-gradient(ellipse at center, rgba(#0b1f2e, .2) 0%, rgba(0, 0, 0, 0) 65%);
  }
}

.c-covers-carousel__text {
  margin-top: 3rem;
  text-align: center;
  font-weight: bold;

  @include bp-medium {
    margin-top: 5rem;
  }
}

.c-covers-carousel__heading {
  font-weight: bold;

  &--align-left {
    text-align: left;
  }
}

.c-covers-carousel__item {
  max-width: 2 * 26 + 311 + px;
  padding: 0 26px;

  &--auto-width {
    max-width: none;
    flex-grow: 0;
    flex-basis: initial;
  }
}

