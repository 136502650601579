$grid-gutter: 5.2rem;
$grid-gutter-small: 1.8rem;

.o-grid {
  display: flex;
  flex-wrap: wrap;
  margin: -$grid-gutter-small / 2;

  @include bp-medium {
    margin: -$grid-gutter / 2;
  }

  &--medium-2 > .o-grid__col {
    @include bp-medium {
      flex: 0 0 auto;
      width: 50%;
    }
  }

  &--medium-3 > .o-grid__col {
    @include bp-medium {
      flex: 0 0 auto;
      width: 33.33%;
    }
  }

  &--medium-4 > .o-grid__col {
    @include bp-medium {
      flex: 0 0 auto;
      width: 25%;
    }
  }

  &--large-3 > .o-grid__col {
    @include bp-large {
      flex: 0 0 auto;
      width: 33.33%;
    }
  }

  &--large-4 > .o-grid__col {
    @include bp-large {
      flex: 0 0 auto;
      width: 25%;
    }
  }
}

.o-grid__col {
  flex: 0 0 auto;
  width: 100%;
  padding: $grid-gutter-small / 2;

  @include bp-medium {
    padding: $grid-gutter / 2;
  }
}

// Triggering proper layout for parent with childres having negative
// vertical margins http://stackoverflow.com/q/13207095
.o-grid__clearfix {
  padding-top: .1px;
  padding-bottom: .1px;
}
