.c-carousel {
  width: 100%;
  overflow: auto;
  position: relative;
  -webkit-overflow-scrolling: touch;

  @include bp-medium {
    overflow: hidden;
  }
}

.c-carousel__container {
  position: relative;
  display: flex;
  padding-left: 0;
  padding-right: 0;
  transition: transform .5s;

  @include bp-minimal-only {
    transform: none !important;
  }

  @include bp-small-only {
    transform: none !important;
  }
}

.c-carousel__item.c-carousel__item {
  max-width: none;
  flex: 1 0 auto;
  align-self: stretch;
  border-left: 5px solid $color-white;
  border-right: 5px solid $color-white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include bp-medium {
    border-left-width: 17.5px;
    border-right-width: 17.5px;
  }

  &:first-child {
    border-left-width: 0;

    @include bp-medium {
      border-left-width: 35px;
    }
  }

  &:last-child {
    border-right-width: 0;

    @include bp-medium {
      border-right-width: 35px;
    }
  }
}

.c-carousel__photo {
  max-width: 100vw;
}

.c-carousel__img {
  display: block;
  height: 450px;
  max-width: 100vw;
  max-height: calc(100vh - 30px);
  object-fit: cover;
}

.c-carousel__text {
  padding: 10px 35px;

  @include bp-medium {
    padding: 10px 0;
  }
}

.c-carousel__prev {
  display: none;

  @include bp-medium {
    display: block;
    left: 50px;
  }
}

.c-carousel__next {
  display: none;

  @include bp-medium {
    display: block;
    right: 50px;
  }
}
