.c-press-single__container {
  @include bp-large {
    padding-bottom: 62%;
    position: relative;
  }
}

.c-press-single__content {
  @include bp-large {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.c-press-single__column {
  background-color: $color-white;
  width: 100%;
  height: 100%;
  float: left;
  @include bp-large {
    width: 50%;
  }
}

.c-press-single__left {
  overflow: hidden;
}

.c-press-single__image {
  width: 100%;
}

.c-press-single__right {
  padding: 3% 5%;
  display: flex;
  flex-direction: column;
}

.c-press-single__right-top {
  text-transform: uppercase;
  font-weight: 600;
}

.c-press-single__right-center {
  padding-top: 1em;
  flex-grow: 1;
  overflow: hidden;

  @include bp-large {
    padding-top: 5em;
  }
}

.c-press-single__heading {
  font-family: $font-freight;
  margin-bottom: .8em;
  font-weight: 600;
}

.c-press-single__excerpt {
  line-height: 1.7;
}

.c-press-single__link {
  display: inline-block;
  color: $color-pale-blue;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;
  padding-bottom: 1em;
  border-bottom: 1px solid $color-pale-blue;
}
