.c-default-carousel {
  position: relative;
  width: 100%;
  max-width: 1400px;
  margin: 7rem auto 0;

  @include bp-medium {
    width: calc(100% - 100px);
    margin-bottom: 16rem;
  }

  &--pale-bg {
    &::before {
      @include bp-medium {
        content: '';
        position: absolute;
        z-index: -1;
        left: 50%;
        width: calc(100vw);
        top: 6rem;
        bottom: 6rem;
        transform: translateX(-50%);
        background: $color-pale-blue-light;
      }
    }
  }
}

.c-default-carousel__item {
  background-size: cover;
  height: 78vw;
  max-height: 900px;
  max-width: 100%;
}

.c-default-carousel__item-img {
  display: none;
}

