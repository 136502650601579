.c-article__title {
  font-weight: normal;
  font-size: 2.3em;
  font-family: $font-freight;
  line-height: 1.5em;
  margin-bottom: 1.5em;
}

.c-article__content {
  padding-bottom: 1rem;
  border-bottom: 1px solid $color-lightest-gray;

  @include bp-medium {
    border-bottom: none;
  }
}
