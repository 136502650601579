.c-about-row {
  display: flex;
  flex-direction: column;

  @include bp-medium {
    flex-direction: row;
    margin-right: -2.5em;
    margin-left: -2.5em;
  }

  &:nth-child(even) {
    @include bp-medium {
      flex-direction: row-reverse;
      margin-bottom: 5rem;
    }
  }

  &:before {
    content: '';
    border-top: 1px solid rgba($color-pale-blue, .7);
    width: 100%;
    position: absolute;
    left: 0;
    @include bp-medium {
      border: none;
    }
  }
}

.c-about-row__column {
  flex: 0 0 auto;
  width: 100%;
  padding: 1em 0;

  @include bp-medium {
    padding: 2.5rem;
    width: 50%;
  }
}

.c-about-row__column-text {
  .c-about-row:nth-child(odd) > & {
    @include bp-medium {
      padding-bottom: 12.5rem;
    }
  }
}

.c-about-row__text,
.c-about-row__column-image {
  display: none;
  @include bp-medium {
    display: block;
  }

  &.is-visible {
    display: block;
  }
}

.c-about-row__header {
  display: flex;
}

.c-about-row__heading {
  margin-bottom: 0;
  flex: 1 1 auto;
  font-family: $font-freight;
  font-size: 3rem;
  font-weight: 600;
}

.c-about-row__button {
  @include btn-reset;
  height: 4rem;
  padding-left: 5px;

  @include bp-medium {
    display: none;
  }

  > .c-about-row__button-icon-minus {
    display: none;
  }

  &.is-open {
    > .c-about-row__button-icon-plus {
      display: none;
    }

    > .c-about-row__button-icon-minus {
      display: block;
    }
  }
}

.c-about-row__button-icon {
  width: 15px;
  height: 15px;
}

.c-about-row__text {
  margin-top: 1.5rem;
  list-style-position: inside;
  line-height: 2;

  li {
    margin: 0;
  }
}

.c-about-row__column-image {
  .c-about-row:nth-child(even) > & {
    @include bp-medium {
      margin-top: -15rem;
    }
  }
  margin-bottom: 3em;
  @include bp-medium {
    margin-bottom: 0;
  }
}

.c-about-row__image {
  width: 100%;
  height: auto;
}
