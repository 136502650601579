.o-container {
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-left: $container-padding;
  padding-right: $container-padding;

  & > & {
    padding-left: 0;
    padding-right: 0;
  }

  @include bp-small {
    padding: 0 $container-padding-small;
  }

  @include bp-full {
    padding: 0 $container-padding-full;
  }

  &--relative {
    position: relative;
  }

  &--main {
    padding-top: 5rem;

    @include bp-small {
      padding-top: 6rem;
    }

    @include bp-medium {
      padding-top: 8rem;
    }
  }

  &--escape {
    margin-left: -$container-padding;
    margin-right: -$container-padding;

    @include bp-small {
      margin-left: -$container-padding-small;
      margin-right: -$container-padding-small;
    }

    @include bp-full {
      margin-left: -$container-padding-full;
      margin-right: -$container-padding-full;
    }
  }
}
