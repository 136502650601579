.c-blog-list-single__link {
  text-decoration: none;
}

.c-blog-list-single__image-container {
  width: 100%;
  position: relative;
  @include shadow(45px, 5px);
}

.c-blog-list-single__image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 45px);
  background-color: rgba($color-lightest-gray, .9);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity .25s;

  &:hover {
    opacity: 1;
  }
}

.c-blog-list-single__image {
  width: 100%;
}

.c-blog-list-single__heading {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1em;
  line-height: 2;
  margin-bottom: 0;
}

.c-blog-list-single__footer {
  line-height: 2;
}
