.c-thumbnail-carousel {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}

.c-thumbnail-carousel__frame {
  height: 400px;
  flex-grow: 1;
  transition: height .25s;

  @include bp-medium {
    height: 800px;
  }
}

.c-thumbnail-carousel__container {
  display: flex;
  height: 100%;
}

.c-thumbnail-carousel__item {
  position: relative;
  flex: 1 0 100%;
  height: 100%;
  background: center center no-repeat;
  background-size: cover;
}

.c-thumbnail-carousel__item-img {
  display: block;
  object-fit: cover;
  height: 400px;
  visibility: hidden;
}

.c-thumbnail-carousel__item-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  font-size: 1.6rem;
  text-align: center;
  color: $color-black;
  background: rgba($color-white, .7);
}

.c-thumbnail-carousel__thumbs-outer {
  margin: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-grow: 1;
  order: 2;
}

.c-thumbnail-carousel__thumbs-inner {
  overflow: hidden;
}

.c-thumbnail-carousel__thumbs {
  display: flex;
  list-style: none;
  margin: 10px auto;
  transition: transform .25s;
}

.c-thumbnail-carousel__thumb {
  width: 100px;
  height: 100px;
  margin: 0;
  opacity: .5;
  transition: opacity .25s;
  flex: 1 0 auto;
  cursor: pointer;

  &.is-active {
    opacity: 1;
  }
}

.c-thumbnail-carousel__thumb-img {
  object-fit: cover;
  height: inherit;
  padding: 10px;
}

.c-thumbnail-carousel__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.c-thumbnail-carousel__prev,
.c-thumbnail-carousel__next {
  display: block;
  width: 40px;
  max-width: 40px;
  height: 40px;
  margin: 20px;
  cursor: pointer;
  flex-grow: 1;

  svg {
    height: inherit;
  }
}

.c-thumbnail-carousel__prev {
  order: 1;
  width: 100px;
}

.c-thumbnail-carousel__next {
  order: 3;
  transform: rotate(180deg);
  width: 100px;
}
