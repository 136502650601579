.o-common-title {
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 1px;
  font-size: 1.3rem;
  font-family: $font-default;
  line-height: 2.15;
  margin: 0;

  @include bp-medium {
    font-size: 1.4rem;
  }
}
