.js-slidein {
  opacity: 0;
  transform: translateY(35px);
  transition: opacity .7s .1s, transform .7s .1s;

  /* stylelint-disable-next-line */
  html.no-js &,
  &.animate {
    opacity: 1;
    transform: translateY(0);
  }
}
