.c-blog-list__list {
  background-color: $color-lightest-gray;
  margin-top: 3rem;
  padding-top: 6rem;
}

.c-blog-list__pages {
  @include clearfix;
  display: flex;
  justify-content: center;
  padding: 48px 0;
  border-top: 1px solid $color-lighter-gray;
  margin-top: 5rem;
}

.c-blog-list__pages-col {
  display: block;
  float: left;
  line-height: 1em;
  padding: .5em;
  width: 2em;
  border: 1px solid transparent;
  text-decoration: none;
  text-align: center;
  color: $color-grey;
  margin-right: 1em;

  &:last-child {
    margin-right: 0;
  }

  &.current {
    border: 1px solid $color-dark-grey;
    color: $color-text;
  }
}

.c-blog-list__pages-col-label {
  text-transform: uppercase;
  width: auto;
  color: $color-text;
}

.c-blog-list__pages-col-icon {
  width: 10px;
  height: 10px;
  fill: $color-dark-grey;
}

.c-blog-list__list-not-found {
  text-align: center;
}
