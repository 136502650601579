.c-social-share {
  display: flex;
  margin: 0;

  @include bp-medium {
    margin: 0 0 4rem;
  }

  &--footer {
    margin: 2rem 0;
  }
}

.c-social-share__item {
  list-style: none;
  margin: 0 2rem 0 0;
}

.c-social-share__link {
  display: block;
}

.c-social-share__icon {
  width: 20px;
  height: 20px;
  fill: $color-pale-blue;

  &--instagram {
    margin-top: 1px;
    height: 18px;
  }
}
