.o-select {
  position: relative;
  width: 100%;
  margin: 10px 0;
}

.o-select__icon {
  width: 10px;
  height: 10px;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: rotate(90deg) translateX(-50%);
  pointer-events: none;
}

.o-select__select {
  appearance: none;
  background-color: transparent;
  width: 100%;
  color: $color-text;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  cursor: pointer;

  &::-ms-expand {
    display: none;
  }

  > option {
    padding: 0;
  }
}


