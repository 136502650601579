.c-author-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 5rem 0;
  padding: 5rem 0;
  width: 100%;

  @include bp-medium {
    flex-direction: row;
  }
}

.c-author-section__photo-container {
  position: relative;
  align-self: center;
  order: 2;

  @include bp-medium {
    order: 1;
    width: 50%;
    max-width: 525px;
    margin-right: 5rem;
  }

  @include bp-large {
    margin-right: 10rem;
  }

  @include shadow(20px, 0);
}

.c-author-section__content-container {
  letter-spacing: .5px;
  font-size: 1.3rem;
  line-height: 2.5rem;
  order: 1;

  @include bp-medium {
    order: 2;
    width: 50%;
    align-self: center;
  }
}

.c-author-section__content {
  font-size: 1.4rem;
}

.c-author-section__title {
  text-transform: uppercase;
  font-family: $font-freight;

  @include bp-medium {
    text-transform: none;
    font-size: 3.2rem;
  }
}

.c-author-section__button {
  margin: 3.5rem 0;

  @include bp-medium {
    margin-top: 5rem;
  }
}
