.c-masonry-grid__column-sizer {
  display: block;
  height: 0;
  width: calc(50% - 10px);
}

.c-masonry-grid__gutter-sizer {
  display: block;
  height: 0;
  width: 20px;
}
