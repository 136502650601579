// Utilities

/* Hide visually but not from screen readers */
.u-hide {
  @include breakpointize(true) {
    left: -9999em !important;
    position: absolute !important;
  }

  &--real {
    display: none;
  }
}

.u-spacer {
  margin-bottom: 1rem;
}

.u-noscroll {
  overflow: hidden;
}

.u-hide\@small {
  display: none;

  @include bp-medium {
    display: block;
  }
}

.u-no-margin {
  @include breakpointize(true) {
    margin: 0;
  }
}

.u-no-padding {
  @include breakpointize(true) {
    padding: 0;
  }
}

.u-color-black {
  color: $color-black;
}

.u-color-pale-blue {
  color: $color-pale-blue;
}

.u-relative {
  position: relative;
}

.u-bg-escape-container {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    width: 100vw;
    left: 50%;
    top: 0;
    bottom: 0;
    background: inherit;
    transform: translateX(-50%);
  }
}

.u-fixed {
  position: fixed;
}

@for $i from 0 through 10 {
  .u-margin-top-#{$i} {
    @include breakpointize(true) {
      margin-top: #{$i}em;
    }
  }

  .u-margin-bottom-#{$i} {
    @include breakpointize(true) {
      margin-bottom: #{$i}em;
    }
  }

  // ENABLE on first use:

  // .u-margin-left-#{$i} {
  //   @include breakpointize(true) {
  //     margin-left: #{$i}em;
  //   }
  // }

  // .u-margin-right-#{$i} {
  //   @include breakpointize(true) {
  //     margin-right: #{$i}em;
  //   }
  // }
}
