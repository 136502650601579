.c-team-members-founders {
  display: flex;
  height: 100%;
  position: relative;
  flex-direction: column;
  @include bp-medium {
    flex-direction: row;
  }
}

.c-team-members-founders__side {
  flex: 0 0 auto;
  padding: 1em 0;
  background-color: $color-lightest-gray;
  display: flex;
  position: relative;
  @include bp-medium {
    flex: 0 0 28%;
    padding: 0;
    margin: 10% 0;
  }
}

.c-team-members-founders__middle {
  flex: 0 0 auto;
  position: relative;
  order: -1;

  @include bp-medium {
    flex: 0 0 44%;
    order: 0;
  }

  &.has-overlay {
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include bp-medium {
      position: relative;
    }
  }
}

.c-team-members-founders__middle-default {
  height: 100%;
  overflow: hidden;
}

.c-team-members-founders__middle-image {
  width: 100%;
  height: auto;
}

.c-team-members-founders__person {
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  @include bp-large {
    padding: 3rem 6rem;
  }
}

.c-team-members-founders__person-name,
.c-team-members-founders__middle-person-heading {
  font-family: $font-freight;
  font-weight: 600;
  font-size: 3.2rem;
}

.c-team-members-founders__person-info {
  margin-bottom: 2em;
}

.c-team-members-founders__hidden {
  display: none;

  &.is-visible {
    display: block;
  }
}

.c-team-members-founders__absolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-team-members-founders__side-image {
  overflow: hidden;
}

.c-team-members-founders__side-image-image {
  width: 100%;
  height: auto;
}

.c-team-members-founders__middle-person {
  background-color: rgba($color-lightest-gray, .95);
  padding: 3rem;
  flex-direction: column;

  &.is-visible {
    display: flex;
  }
}

.c-team-members-founders__middle-person-content {
  margin-top: 1em;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  @include bp-medium {
    padding: 2em;
  }
}

.c-team-members-founders__middle-close-row {
  text-align: right;
}

.c-team-members-founders__middle-close {
  @include btn-reset;
  text-transform: uppercase;
  // Based on .c-press-single__link
  color: $color-pale-blue;
  font-weight: 600;
  padding-bottom: 1em;
  border-bottom: 1px solid $color-pale-blue;
}
