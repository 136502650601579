.c-team-members-member__image {
  width: 100%;
  height: auto;
}

.c-team-members-member__name {
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 2;
  margin-top: 1.8rem;
  margin-bottom: 0;
}

.c-team-members-member__position {
  font-size: 1.4rem;
  line-height: 2;
  margin-bottom: 1em;
}

.c-team-members-member__desc {
  line-height: 1.7;
  margin-bottom: 0;
}

.c-team-members__members-section {
  margin-bottom: 7rem;
  @include bp-medium {
    margin-bottom: 14rem;
  }
}

.c-team-members__members-header {
  margin-bottom: 7rem;
  @include bp-medium {
    margin-bottom: 13rem;
  }
}
