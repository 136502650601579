$lory-slide-padding: 1rem;

.o-lory__frame {
  position: relative;
  min-width: 100%;
  overflow: hidden;
  margin: 0;
}

.o-lory__container {
  display: flex;
}

.o-lory__slide {
  flex: 1 0 100%;
  padding-left: $lory-slide-padding;
  padding-right: $lory-slide-padding;
}

.o-lory__prev,
.o-lory__next {
  display: block;
  position: absolute;
  top: 50%;
  width: 6rem;
  height: 6rem;
  padding: 1rem;
  transition: color .2s, background .2s;
  cursor: pointer;

  .c-covers-carousel & {
    display: none;
    top: calc(50% - 40px);
    transform: translateX(0) translateY(-50%);

    @include bp-medium {
      display: block;
    }
  }

  &:focus,
  &:hover {
    color: $color-white;
    background: rgba($color-pale-blue, .5);
  }

  > svg {
    height: 100%;
  }
}

.o-lory__prev {
  left: 0;
  transform: translateX(-50%) translateY(-50%);

  .c-default-carousel & {
    transform: translateX(0) translateY(-50%);

    @include bp-medium {
      transform: translateX(-50%) translateY(-50%);
    }
  }

  @include bp-full {
    transform: translateX(-50%) translateY(-50%);
  }
}

.o-lory__next {
  right: 0;
  transform: translateX(50%) translateY(-50%);

  .c-default-carousel & {
    transform: translateX(0) translateY(-50%);

    @include bp-medium {
      transform: translateX(50%) translateY(-50%);
    }
  }

  @include bp-full {
    transform: translateX(50%) translateY(-50%);
  }

  > svg {
    transform: rotate(180deg);
  }
}

.o-lory__dots {
  display: flex;
  justify-content: center;
  margin: 3rem auto;
  list-style: none;
}

.o-lory__dots-item {
  position: relative;
  display: block;
  width: 10px;
  height: 10px;
  margin: .5rem 1.25rem;
  border-radius: 50%;
  background: $color-pale-blue;
  opacity: .5;

  &::before {
    content: '';
    position: absolute;
    border-radius: inherit;
    left: 50%;
    top: 50%;
    display: block;
    width: 0;
    height: 0;
    background: darken($color-pale-blue, 20%);
    transform: translateX(-50%) translateY(-50%);
    transition: width .25s, height .25s;
    opacity: .5;
  }

  &.active::before {
    width: 15px;
    height: 15px;
  }
}
