.c-form-element {
  position: relative;
  border: 1px solid #eceff7;
  border-radius: 4px;
  font-size: 1.6rem;

  @include bp-medium {
    font-size: 2.4rem;
  }

  &--with-btn {
    .c-form-element__input {
      padding-right: 8rem;

      @include bp-medium {
        padding-right: 10rem;
      }
    }
  }
}

.c-form-element__input {
  width: 100%;
  padding: 1.8rem;
  appearance: none;
  background: none;
  border: 0;

  @include bp-medium {
    padding: 2.5rem;
  }
}

.c-form-element__btn {
  position: absolute;
  right: 0;
  left: auto;
  top: 50%;
  padding: 2.5rem;
  line-height: 2.4rem;
  font-size: 1.3rem;
  font-weight: normal;
  text-transform: uppercase;
  color: $color-text;
  transform: translateY(-50%);
  transition: color .1s;

  &[disabled] {
    color: #d9dce4;
    cursor: default;
  }
}
