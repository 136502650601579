// Page

html {
  background: $color-bg;
  color: $color-text;
  font-family: $font-default;
  font-weight: 300;
  font-size: 10px;
  line-height: 1.3;
}

body {
  position: relative;
  font-size: 1.3rem;
  overflow-x: hidden;

  @include bp-medium {
    font-size: 1.4rem;
  }

  &.is-nav-open { /* stylelint-disable-line selector-no-qualifying-type */
    overflow: hidden;
  }

  &.in-transition { /* stylelint-disable-line selector-no-qualifying-type */
    transition: transform .5s ease;
  }
}

// Headings

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  color: inherit;
}

h1, h2 {
  line-height: 1.375;
  margin: 0 0 2rem;
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.7rem;
}

h4 {
  font-size: 1em;
}

// Horizontal line

hr {
  background-color: $color-border;
  border: 0;
  display: block;
  height: 1px;
  margin: 1.3rem auto;
}

// Links

a {
  color: $color-link;
  text-decoration: underline;

  &:hover {
    color: $color-text;
  }
}

// Lists

li {
  margin-bottom: .5rem;

  > ul,
  > ol {
    margin-bottom: 0;
    margin-left: 1rem;
    margin-top: .5rem;
  }
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}
