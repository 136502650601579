.c-callout {
  position: relative;
  min-width: 100%;
  margin: 0 (-$container-padding);
  border-top: 1px solid $color-light-grey;
  border-bottom: 1px solid $color-light-grey;

  @include bp-small {
    margin-left: -$container-padding-small;
    margin-right: -$container-padding-small;
  }

  @include bp-medium {
    display: flex;
    margin-left: -2.5rem;
    margin-right: -2.5rem;
    border-top: 0;
    border-bottom: 0;
  }

  &--right {
    flex-direction: row-reverse;
  }
}

.c-callout__media,
.c-callout__content {
  @include bp-medium {
    margin: 0 2.5rem;
  }
}

.c-callout__media {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @include bp-medium {
    position: static;
    flex: 1 1 auto;
  }
}

.c-callout__media-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.c-callout__content {
  position: relative;
  padding: 5rem $container-padding;
  background: rgba($color-white, .9);

  @include bp-small {
    padding-left: $container-padding-small;
    padding-right: $container-padding-small;
  }

  @include bp-medium {
    width: 300px;
    flex: 1 0 auto;
    padding: 0;
  }
}

.c-callout__icon {
  height: 1em;
  width: 1em;
  font-size: 7rem;
  margin-bottom: 5rem;
}

.c-callout__heading {
  margin-bottom: 3rem;
  font-weight: bold;
  color: $color-pale-blue;

  @include bp-medium {
    color: $color-black;
  }
}
