.c-filter-list {
  display: flex;
  flex-grow: 1;
  max-width: 620px;
  margin: 0 30px 0 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  justify-content: space-between;

  @include bp-large {
    margin: 0 30px;
  }
}

.c-filter-list__label {
  color: $color-text;
  font-weight: bold;
  white-space: nowrap;

  &:after {
    content: ':';
  }

  @include bp-large {
    margin-right: 20px;
  }
}

.c-filter-list__option {
  color: $color-grey;
  letter-spacing: 0;
  cursor: pointer;
  transition: color .25s;

  &:hover,
  &.active {
    color: $color-text;
  }

  @include bp-large {
    letter-spacing: 1px;
  }
}
