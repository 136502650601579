.c-header {
  position: relative;
  padding-top: 3rem;
  width: 100%;
  z-index: $z-header;

  @include bp-medium {
    padding-top: 6rem;
  }

  &.is-nav-open {
    position: fixed;
    top: 0;
  }

  &--light:not(.is-nav-open) {
    color: $color-white;
    fill: $color-white;

    .c-header__logo-img {
      background-image: url('../assets/images/logo-white.svg');
    }
  }

  &--fixed {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
  }

  > .o-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.c-header__logo {
  display: block;
  width: 160px;
  transition: opacity .25s;
  opacity: .7;

  &:hover {
    opacity: 1;
  }
}

.c-header__logo-img {
  display: block;
  width: 160px;
  height: 64px;
  background: center center no-repeat;
  background-image: url('../assets/images/logo.svg');
  background-size: contain;
}

.c-header__menu-toggle {
  @include btn-reset;
  font-size: 56px;
  min-width: 56px;
  text-align: center;

  &.is-nav-open {
    .c-header__menu-icon {
      display: none;
    }

    .c-header__menu-icon--close {
      display: block;
    }
  }
}

.c-header__menu-icon {
  display: block;
  fill: currentColor;
  width: 1em;
  height: .55em;
  margin: 0 auto;

  &--close {
    width: .8em;
    height: .8em;
    display: none;
    transform: rotate(45deg);
  }
}
