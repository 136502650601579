.c-alt-section {
  background: $color-pale-blue-light;
  padding: 4.3rem 0;

  @include bp-medium {
    padding: 9.5rem 0;
  }
}

.c-alt-section__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.5rem;

  @include bp-medium {
    margin-bottom: 8rem;
  }
}

.c-alt-section__heading {
  font-size: 1.4rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;

  @include bp-medium {
    letter-spacing: .5px;
    text-transform: none;
    font-weight: normal;
    font-size: 3.2rem;
    font-family: $font-freight;
  }
}
