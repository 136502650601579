// DIN
@font-face {
  font-family: DIN;
  src: url('../assets/fonts/din-light.woff2') format('woff2'),
    url('../assets/fonts/din-light.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: DIN;
  src: url('../assets/fonts/din-medium.woff2') format('woff2'),
    url('../assets/fonts/din-medium.woff') format('woff');
  font-weight: 600;
}

// Freight (both Uppercase and lowercase)
@font-face {
  font-family: Freight;
  src: url('../assets/fonts/freight-display-semibold.woff2') format('woff2'),
    url('../assets/fonts/freight-display-semibold.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Freight;
  src: url('../assets/fonts/freight-text-semibold.woff2') format('woff2'),
    url('../assets/fonts/freight-text-semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Freight;
  src: url('../assets/fonts/freight-text-semibold-italic.woff2') format('woff2'),
    url('../assets/fonts/freight-text-semibold-italic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

// Freight (Smallcaps)
@font-face {
  font-family: 'Freight Smallcaps';
  src: url('../assets/fonts/freight-text-light.woff2') format('woff2'),
    url('../assets/fonts/freight-text-light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Freight Smallcaps';
  src: url('../assets/fonts/freight-text-light-italic.woff2') format('woff2'),
    url('../assets/fonts/freight-text-light-italic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Freight Smallcaps';
  src: url('../assets/fonts/freight-text-book.woff2') format('woff2'),
    url('../assets/fonts/freight-text-book.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Freight Smallcaps';
  src: url('../assets/fonts/freight-text-book-italic.woff2') format('woff2'),
    url('../assets/fonts/freight-text-book-italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Freight Smallcaps';
  src: url('../assets/fonts/freight-text-medium.woff2') format('woff2'),
    url('../assets/fonts/freight-text-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Freight Smallcaps';
  src: url('../assets/fonts/freight-text-medium-italic.woff2') format('woff2'),
    url('../assets/fonts/freight-text-medium-italic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Freight Smallcaps';
  src: url('../assets/fonts/freight-text-bold.woff2') format('woff2'),
    url('../assets/fonts/freight-text-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Freight Smallcaps';
  src: url('../assets/fonts/freight-text-bold-italic.woff2') format('woff2'),
    url('../assets/fonts/freight-text-bold-italic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Freight Smallcaps';
  src: url('../assets/fonts/freight-text-black.woff2') format('woff2'),
    url('../assets/fonts/freight-text-black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Freight Smallcaps';
  src: url('../assets/fonts/freight-text-black-italic.woff2') format('woff2'),
    url('../assets/fonts/freight-text-black-italic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

.font-din {
  font-family: DIN, sans-serif;
}

.font-freight {
  font-family: Freight, serif;
}

.font-freight-smallcaps {
  font-family: 'Freight Smallcaps', serif;
}
