.c-landing {
  width: 100%;
  height: 450px;
  max-height: 100vh;
  overflow: hidden;
  background: center center no-repeat;
  background-size: cover;

  @include bp-medium {
    height: 800px;
  }
}

.c-landing__img {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
  visibility: hidden;
}

