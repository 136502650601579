.c-careers__top {
  margin-bottom: 6rem;
  @include bp-medium {
    margin-bottom: 12.5rem;
  }
}

.c-careers__carousel {
  margin-bottom: 6rem;
  @include bp-medium {
    margin-bottom: 13rem;
  }
}

.c-careers__below-carousel-container {
  max-width: 1160px;
}

.c-careers__info {
  margin: 5rem 0;
  @include bp-medium {
    margin: 10rem 0;
  }
}

.c-careers__info-single-heading {
  font-family: $font-freight;
  font-size: 3.2rem;
}

.c-careers__info-single-content {
  list-style-position: inside;
}

.c-careers__culinary-agents {
  margin-bottom: 7rem;
  @include bp-medium {
    margin-bottom: 15rem;
  }
}
