.o-full-width {
  width: calc(100vw - 8px);
  margin-left: -$container-padding;

  @include bp-small {
    margin-left: -$container-padding-small;
  }

  @include bp-full {
    margin-left: calc(((100vw - #{$bp-full}) / -2) - #{$container-padding-full});
  }
}
