.o-asymmetric-layout {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @include bp-medium {
    flex-direction: row;
  }
}

.o-asymmetric-layout__main {
  @include bp-medium {
    width: 65%;
  }

  @include bp-large {
    width: 70%;
  }
}

.o-asymmetric-layout__sidebar {
  display: flex;
  flex-direction: column;

  @include bp-medium {
    width: 25%;
  }

  @include bp-large {
    width: 20%;
  }
}

.o-asymmetric-layout__social-share {
  order: 1;

  @include bp-medium {
    order: 0;
  }
}

