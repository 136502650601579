.c-social-snippets {
  margin: 5rem 0 2rem;

  @include bp-medium {
    margin: 15rem -2.5rem;
  }

  .o-lory__dots {
    @include bp-medium {
      display: none;
    }
  }
}

.c-social-snippets__item {
  position: relative;
  flex: 1 0 100%;
  padding: 0 1rem;

  @include bp-medium {
    padding-left: 4.5rem;
    margin: 0 2.5rem;
    flex: 1 1 auto;
  }
}

.c-social-snippets__title {
  font-weight: bold;
}

.c-social-snippets__text {
  margin: 2rem 0 3rem;
}

.c-social-snippets__icon {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  height: 1em;
  width: 1em;
  font-size: 1.4em;
  color: $color-pale-blue;

  @include bp-medium {
    display: block;
  }
}
