.c-press-carousel-single {
  width: 100%;

  @include bp-medium {
    &:after {
      // Based on: c-covers-carousel__photo
      content: '';
      display: block;
      width: 100%;
      height: 60px;
      background: radial-gradient(ellipse closest-side at 50% 65%, rgba(#0b1f2e, .2) 0%, rgba(0, 0, 0, 0) 85%);
    }
  }
}

.c-press-carousel-single__container {
  @include bp-medium {
    padding-bottom: 52%;
    position: relative;
  }
}

.c-press-carousel-single__content {
  @include bp-medium {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.c-press-carousel-single__column {
  height: 100%;
  @include bp-medium {
    width: 50%;
    float: left;
  }
}

.c-press-carousel-single__left {
  overflow: hidden;
}

.c-press-carousel-single__image {
  width: 100%;
}

.c-press-carousel-single__right {
  padding: 5%;
  display: flex;
  flex-direction: column;
  background-color: $color-lightest-gray;
}

.c-press-carousel-single__right-top {
  text-transform: uppercase;
  font-weight: 600;
}

.c-press-carousel-single__right-bottom {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.c-press-carousel-single__heading {
  font-weight: 600;
  font-size: 3rem;
  font-family: $font-freight;
  margin-bottom: 1.4rem;
}

.c-press-carousel-single__source {
  letter-spacing: 1.5px;
  font-weight: 600;
  margin-bottom: 2rem;
}

.c-press-carousel-single__excerpt {
  margin-bottom: 4rem;
  overflow: hidden;
}
