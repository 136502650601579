.c-tile {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  margin-bottom: 20px;

  @include bp-medium {
    width: calc(50% - 10px);
    padding-bottom: 0;
    height: 360px;
  }


  &--large {
    @include bp-medium {
      height: 740px;
    }
  }
}

.c-tile__bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-position: center center;

  @include bp-medium {
    @include absolute-fullscreen;
  }
}

.c-tile__content {
  position: absolute;
  z-index: $z-base;
  top: 50%;
  left: 50%;
  width: 70%;
  height: 70%;
  max-width: 300px;
  max-height: 300px;
  transform: translate3d(-50%, -50%, 0);
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;

  @include bp-medium {
    width: 50%;
    height: 50%;
  }
}

.c-tile__content-img {
  margin: 0 auto;
  max-width: 100%;
  max-height: 100%;
}
