.c-press__intro {
  max-width: 900px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  margin-bottom: 8rem;
  @include bp-medium {
    margin-bottom: 16rem;
  }
}

.c-press__intro-heading {
  font-family: $font-freight;
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: .8em;
  letter-spacing: 1px;
  text-transform: uppercase;

  @include bp-medium {
    line-height: 1.5em;
    letter-spacing: 4px;
  }
}

.c-press__intro-paragraph {
  max-width: 770px;
  margin-right: auto;
  margin-left: auto;
  letter-spacing: .5px;
}

.c-press__carousel {
  position: relative;
  margin-bottom: 4rem;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  @include bp-medium {
    margin-bottom: 10rem;
  }
}

.c-press__carousel-container {
  width: 100%;
}

.c-press__carousel-prev,
.c-press__carousel-next {
  top: 230px;
  background: rgba($color-white, .25);
  transform: none;

  @include bp-medium {
    top: 50%;
    background: none;
    transform: translateY(-50%);
  }
}

.c-press__carousel-prev {
  left: 20px;

  @include bp-medium {
    left: -1rem;
  }

  @include bp(1120px) {
    left: calc((1100px - 100vw) / 2);
  }

  @include bp(1368px) {
    left: -134px;
  }
}

.c-press__carousel-next {
  right: 20px;

  @include bp-medium {
    right: -1rem;
  }

  @include bp(1120px) {
    right: calc((1100px - 100vw) / 2);
  }

  @include bp(1368px) {
    right: -134px;
  }
}

.c-press__filter-bar {
  border-top: 1px solid $color-lighter-gray;
  padding-top: 2.9rem;
  margin-bottom: 2em;
}

.c-press__list {
  background-color: $color-lightest-gray;
  padding-top: 6rem;
}

.c-press__list-not-found {
  text-align: center;
}

.c-press__more {
  padding: 48px 0;
  border-top: 1px solid $color-lighter-gray;
  margin-top: 5rem;
  text-align: center;
}

.c-press__more-link {
  text-decoration: none;
  text-transform: uppercase;
  color: $color-grey;

  &.is-hidden {
    display: none;
  }
}
