//Based on https://github.com/WordPress/WordPress/blob/4.6.1/wp-content/themes/twentyfifteen/style.css
/* stylelint-disable */
@mixin blog-single-content-vendor {
  /**
   * 8.0 Alignments
   */

  .alignleft {
  	display: inline;
  	float: left;
  }

  .alignright {
  	display: inline;
  	float: right;
  }

  .aligncenter {
  	display: block;
  	margin-right: auto;
  	margin-left: auto;
  }

  blockquote.alignleft,
  .wp-caption.alignleft,
  img.alignleft {
  	margin: 0.4em 1.6em 1.6em 0;
  }

  blockquote.alignright,
  .wp-caption.alignright,
  img.alignright {
  	margin: 0.4em 0 1.6em 1.6em;
  }

  blockquote.aligncenter,
  .wp-caption.aligncenter,
  img.aligncenter {
  	clear: both;
  	margin-top: 0.4em;
  	margin-bottom: 1.6em;
  }

  .wp-caption.alignleft,
  .wp-caption.alignright,
  .wp-caption.aligncenter {
  	margin-bottom: 1.2em;
  }
}
/* stylelint-enable */
