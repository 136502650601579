.c-blog-single__container {
  @include clearfix;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.c-blog-single__main {
  width: 100%;
  float: left;

  @include bp-medium {
    width: calc(100% - 220px);
    padding-right: 5%;
  }

  @include bp-large {
    padding-right: 10%;
  }
}

.c-blog-single__sidebar {
  width: 100%;
  float: left;
  margin-top: 2em;

  @include bp-medium {
    margin-top: 0;
    width: 220px;
  }
}

.c-blog-single__datecat {
  text-transform: uppercase;
  margin-bottom: 1.7em;
  font-weight: 600;
}

.c-blog-single__datecat-date {
  color: $color-grey;
}

.c-blog-single__datecat-separator {
  padding: 0 10px;
}

.c-blog-single__datecat-category {
  text-decoration: none;
  color: $color-pale-blue;
}

.c-blog-single__title {
  font-weight: 600;
  font-size: 2.3em;
  font-family: $font-freight;
  line-height: 1.5em;
  margin-bottom: 1.5em;
}

.c-blog-single__share-label {
  text-transform: uppercase;
  padding-right: 10px;
  float: left;
  font-weight: 600;
}

.c-blog-single__share-link {
  text-decoration: none;
  padding: 0 10px;
  float: left;
}

.c-blog-single__share-icon {
  height: 16px;
  width: 16px;
  fill: $color-pale-blue;
  stroke: $color-pale-blue;
}

.c-blog-single__more-list {
  background-color: $color-lightest-gray;
  margin-top: 4rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
  @include bp-medium {
    margin-top: 8rem;
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
}

.c-blog-single__more-header {
  display: flex;
}

.c-blog-single__more-heading {
  margin: 0;
  line-height: normal;
  flex: 1 0;
  font-weight: 600;
  font-family: $font-freight;
  font-size: 2.3em;
  margin-bottom: 4rem;
  @include bp-medium {
    margin-bottom: 8rem;
  }
}

// Post content fron WordPress editor
.c-blog-single__content {
  @include blog-single-content-vendor;
  font-weight: 400;

  p {
    margin-bottom: 2em;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  // Image caption
  // WordPress adds 10px to width of caption
  // https://github.com/WordPress/WordPress/blob/4.6.1/wp-includes/media.php#L1532
  // TODO: set "html5 caption"?

  .wp-caption {
    max-width: 100%;

    > .wp-caption-text {
      padding-top: 27px;
      padding-bottom: 17px;
      border-bottom: 1px solid $color-lighter-gray;
      font-weight: normal;
      font-size: .9em;
      line-height: normal;
    }
  }
}
