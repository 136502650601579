/*
  Project: Easton Porter
  Author: Xfive
 */
/* Clearfix */
/* 375px */
/* 768px */
/* 1024px */
/* 1600px */
/* Custom */
/**
 * Set the global `box-sizing` state to `border-box`.
 *
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 * paulirish.com/2012/box-sizing-border-box-ftw
 */
html {
  box-sizing: border-box;
}

html *,
html *:before,
html *:after {
  box-sizing: inherit;
}

@font-face {
  font-family: DIN;
  src: url("../assets/fonts/din-light.woff2") format("woff2"), url("../assets/fonts/din-light.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: DIN;
  src: url("../assets/fonts/din-medium.woff2") format("woff2"), url("../assets/fonts/din-medium.woff") format("woff");
  font-weight: 600;
}

@font-face {
  font-family: Freight;
  src: url("../assets/fonts/freight-display-semibold.woff2") format("woff2"), url("../assets/fonts/freight-display-semibold.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Freight;
  src: url("../assets/fonts/freight-text-semibold.woff2") format("woff2"), url("../assets/fonts/freight-text-semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Freight;
  src: url("../assets/fonts/freight-text-semibold-italic.woff2") format("woff2"), url("../assets/fonts/freight-text-semibold-italic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Freight Smallcaps';
  src: url("../assets/fonts/freight-text-light.woff2") format("woff2"), url("../assets/fonts/freight-text-light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Freight Smallcaps';
  src: url("../assets/fonts/freight-text-light-italic.woff2") format("woff2"), url("../assets/fonts/freight-text-light-italic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Freight Smallcaps';
  src: url("../assets/fonts/freight-text-book.woff2") format("woff2"), url("../assets/fonts/freight-text-book.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Freight Smallcaps';
  src: url("../assets/fonts/freight-text-book-italic.woff2") format("woff2"), url("../assets/fonts/freight-text-book-italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Freight Smallcaps';
  src: url("../assets/fonts/freight-text-medium.woff2") format("woff2"), url("../assets/fonts/freight-text-medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Freight Smallcaps';
  src: url("../assets/fonts/freight-text-medium-italic.woff2") format("woff2"), url("../assets/fonts/freight-text-medium-italic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Freight Smallcaps';
  src: url("../assets/fonts/freight-text-bold.woff2") format("woff2"), url("../assets/fonts/freight-text-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Freight Smallcaps';
  src: url("../assets/fonts/freight-text-bold-italic.woff2") format("woff2"), url("../assets/fonts/freight-text-bold-italic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Freight Smallcaps';
  src: url("../assets/fonts/freight-text-black.woff2") format("woff2"), url("../assets/fonts/freight-text-black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Freight Smallcaps';
  src: url("../assets/fonts/freight-text-black-italic.woff2") format("woff2"), url("../assets/fonts/freight-text-black-italic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

.font-din {
  font-family: DIN, sans-serif;
}

.font-freight {
  font-family: Freight, serif;
}

.font-freight-smallcaps {
  font-family: 'Freight Smallcaps', serif;
}

/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Prevent adjustments of font size after orientation changes in IE and iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0;
}

/* HTML5 display definitions
       ========================================================================== */
/**
     * Add the correct display in IE <10.
     * Add the correct display in Edge, IE, and Firefox for `details` or `summary`.
     * Add the correct display in IE for `main`.
     */
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

/**
     * Add the correct display in IE <10.
     */
audio,
canvas,
progress,
video {
  display: inline-block;
}

/**
     * Add the correct display and remove excess height in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
     * Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  vertical-align: baseline;
}

/**
     * Add the correct display in IE <11, Safari <8, and Firefox <22.
     * 1. Add the correct display in IE.
     */
template,
[hidden] {
  display: none;
}

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0;
}

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox <40.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit;
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

/**
     * Add the correct font style in Android <4.4.
     */
dfn {
  font-style: italic;
}

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  /* Set 1 unit of vertical rhythm on the top and bottom margins. */
  margin: 0.75em 0;
}

/**
     * Add the correct background and color in IE <10.
     */
mark {
  background-color: #ff0;
  color: #000;
}

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%;
}

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Remove the border on images inside links in IE <11.
     */
img {
  border-style: none;
}

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden;
}

/* Grouping content
       ========================================================================== */
/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1.5em 40px;
}

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

/* Forms
       ========================================================================== */
/**
     * Known issues:
     * - By default, Chrome on OS X and Safari on OS X allow very limited styling of
     *   select, unless a border property is set. The default font weight on
     *   optgroup elements cannot safely be changed in Chrome on OSX and Safari on
     *   OS X.
     * - It is recommended that you do not style checkbox and radio inputs as
     *   Firefox's implementation does not respect box-sizing, padding, or width.
     * - Certain font size values applied to number inputs cause the cursor style of
     *   the decrement button to change from default to text.
     * - The search input is not fully stylable by default. In Chrome and Safari on
     *   OSX/iOS you can't control font, padding, border, or background. In Chrome
     *   and Safari on Windows you can't control border properly. It will apply
     *   border-width but will only show a border color (which cannot be controlled)
     *   for the outer 1px of that border. Applying -webkit-appearance: textfield
     *   addresses these issues without removing the benefits of search inputs (e.g.
     *   showing past searches). Safari (but not Chrome) will clip the cancel button
     *   on when it has padding (and textfield appearance).
     */
/**
     * 1. Change font properties to `inherit` in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     * 3. Address `font-family` inconsistency between `textarea` and other form in IE 7
     * 4. Improve appearance and consistency with IE 6/7.
     */
button,
input,
optgroup,
select,
textarea {
  font: inherit;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible;
}

/**
     * 1. Add the correct box sizing in IE <11.
     * 2. Remove the padding in IE <11.
     * 3. Remove excess padding in IE 7.
     *    Known issue: excess padding remains in IE 6.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on OS X.
       */
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
     * Correct the text style of placeholders in Chrome, Edge, and Safari.
     */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     * 4. Correct alignment displayed oddly in IE 6/7.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  white-space: normal;
  /* 1 */
  color: inherit;
  /* 2 */
  padding: 0;
  /* 3 */
}

/**
     * Restore the font weight unset by a previous rule.
     */
optgroup {
  font-weight: bold;
}

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto;
}

/**
 * As well as using normalize.css, it is often advantageous to remove all
 * margins from certain elements.
 */
body,
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
dl, dd, ol, ul,
form, fieldset, legend,
figure,
table, th, td, caption,
hr {
  margin: 0;
  padding: 0;
}

/**
 * Give a help cursor to elements that give extra info on `:hover`.
 */
abbr[title],
dfn[title] {
  cursor: help;
}

/**
 * Remove underlines from potentially troublesome elements.
 */
u,
ins {
  text-decoration: none;
}

/**
 * Apply faux underlines to inserted text via `border-bottom`.
 */
ins {
  border-bottom: 1px solid;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:focus {
  outline: none;
}

/**
 * Where `margin-bottom` is concerned, this value will be the same as the
 * base line-height. This allows us to keep a consistent vertical rhythm.
 * As per: csswizardry.com/2012/06/single-direction-margin-declarations
 */
h1, h2, h3, h4, h5, h6,
ul, ol, dl,
blockquote, p, address,
hr,
table,
fieldset, figure,
pre {
  margin-bottom: 1.3rem;
}

/**
 * Where `margin-left` is concerned we want to try and indent certain elements
 * by a consistent amount. Define that amount once, here.
 */
ul, ol, dd {
  margin-left: 1rem;
}

svg,
img {
  display: block;
  max-width: 100%;
}

svg {
  fill: currentColor;
}

p {
  line-height: 2;
}

:focus {
  outline-color: #7d99b3;
}

.o-lory__frame {
  position: relative;
  min-width: 100%;
  overflow: hidden;
  margin: 0;
}

.o-lory__container {
  display: flex;
}

.o-lory__slide {
  flex: 1 0 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}

.o-lory__prev,
.o-lory__next {
  display: block;
  position: absolute;
  top: 50%;
  width: 6rem;
  height: 6rem;
  padding: 1rem;
  transition: color .2s, background .2s;
  cursor: pointer;
}

.c-covers-carousel .o-lory__prev, .c-covers-carousel
.o-lory__next {
  display: none;
  top: calc(50% - 40px);
  transform: translateX(0) translateY(-50%);
}

@media (min-width: 768px) {
  .c-covers-carousel .o-lory__prev, .c-covers-carousel
  .o-lory__next {
    display: block;
  }
}

.o-lory__prev:focus, .o-lory__prev:hover,
.o-lory__next:focus,
.o-lory__next:hover {
  color: #fff;
  background: rgba(125, 153, 179, 0.5);
}

.o-lory__prev > svg,
.o-lory__next > svg {
  height: 100%;
}

.o-lory__prev {
  left: 0;
  transform: translateX(-50%) translateY(-50%);
}

.c-default-carousel .o-lory__prev {
  transform: translateX(0) translateY(-50%);
}

@media (min-width: 768px) {
  .c-default-carousel .o-lory__prev {
    transform: translateX(-50%) translateY(-50%);
  }
}

@media (min-width: 1600px) {
  .o-lory__prev {
    transform: translateX(-50%) translateY(-50%);
  }
}

.o-lory__next {
  right: 0;
  transform: translateX(50%) translateY(-50%);
}

.c-default-carousel .o-lory__next {
  transform: translateX(0) translateY(-50%);
}

@media (min-width: 768px) {
  .c-default-carousel .o-lory__next {
    transform: translateX(50%) translateY(-50%);
  }
}

@media (min-width: 1600px) {
  .o-lory__next {
    transform: translateX(50%) translateY(-50%);
  }
}

.o-lory__next > svg {
  transform: rotate(180deg);
}

.o-lory__dots {
  display: flex;
  justify-content: center;
  margin: 3rem auto;
  list-style: none;
}

.o-lory__dots-item {
  position: relative;
  display: block;
  width: 10px;
  height: 10px;
  margin: .5rem 1.25rem;
  border-radius: 50%;
  background: #7d99b3;
  opacity: .5;
}

.o-lory__dots-item::before {
  content: '';
  position: absolute;
  border-radius: inherit;
  left: 50%;
  top: 50%;
  display: block;
  width: 0;
  height: 0;
  background: #4b667f;
  transform: translateX(-50%) translateY(-50%);
  transition: width .25s, height .25s;
  opacity: .5;
}

.o-lory__dots-item.active::before {
  width: 15px;
  height: 15px;
}

html {
  background: #fff;
  color: #303437;
  font-family: "DIN", helvetica, sans-serif;
  font-weight: 300;
  font-size: 10px;
  line-height: 1.3;
}

body {
  position: relative;
  font-size: 1.3rem;
  overflow-x: hidden;
}

@media (min-width: 768px) {
  body {
    font-size: 1.4rem;
  }
}

body.is-nav-open {
  /* stylelint-disable-line selector-no-qualifying-type */
  overflow: hidden;
}

body.in-transition {
  /* stylelint-disable-line selector-no-qualifying-type */
  transition: transform .5s ease;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  color: inherit;
}

h1, h2 {
  line-height: 1.375;
  margin: 0 0 2rem;
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.7rem;
}

h4 {
  font-size: 1em;
}

hr {
  background-color: #ccc;
  border: 0;
  display: block;
  height: 1px;
  margin: 1.3rem auto;
}

a {
  color: #303437;
  text-decoration: underline;
}

a:hover {
  color: #303437;
}

li {
  margin-bottom: .5rem;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1rem;
  margin-top: .5rem;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}

.o-btn-bare {
  cursor: pointer;
  appearance: none;
  background: none;
  padding: 0;
  border: 0;
  color: inherit;
  text-transform: uppercase;
  font-weight: normal;
}

.o-btn, .o-lnk {
  display: block;
  width: 100%;
  appearance: none;
  background: none;
  padding: 1.8rem 2rem;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 3rem;
  border: 1px solid;
  color: inherit;
  cursor: pointer;
  letter-spacing: 1px;
  transition: all .25s;
}

@media (min-width: 768px) {
  .o-btn, .o-lnk {
    display: inline-block;
    width: auto;
    padding: 1.6rem 3.1rem;
  }
}

.o-btn--pale-blue {
  border-color: #7d99b3;
  color: #7d99b3;
}

.o-btn--pale-blue:hover {
  background-color: #7d99b3;
  color: #fff;
}

@media (min-width: 768px) {
  .o-lnk {
    padding-left: 0;
    padding-right: 0;
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-radius: 0;
  }
}

.o-common-title {
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 1px;
  font-size: 1.3rem;
  font-family: "DIN", helvetica, sans-serif;
  line-height: 2.15;
  margin: 0;
}

@media (min-width: 768px) {
  .o-common-title {
    font-size: 1.4rem;
  }
}

.o-container {
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.o-container > .o-container {
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 375px) {
  .o-container {
    padding: 0 35px;
  }
}

@media (min-width: 1600px) {
  .o-container {
    padding: 0 100px;
  }
}

.o-container--relative {
  position: relative;
}

.o-container--main {
  padding-top: 5rem;
}

@media (min-width: 375px) {
  .o-container--main {
    padding-top: 6rem;
  }
}

@media (min-width: 768px) {
  .o-container--main {
    padding-top: 8rem;
  }
}

.o-container--escape {
  margin-left: -15px;
  margin-right: -15px;
}

@media (min-width: 375px) {
  .o-container--escape {
    margin-left: -35px;
    margin-right: -35px;
  }
}

@media (min-width: 1600px) {
  .o-container--escape {
    margin-left: -100px;
    margin-right: -100px;
  }
}

.o-full-width {
  width: calc(100vw - 8px);
  margin-left: -15px;
}

@media (min-width: 375px) {
  .o-full-width {
    margin-left: -35px;
  }
}

@media (min-width: 1600px) {
  .o-full-width {
    margin-left: calc(((100vw - 1600px) / -2) - 100px);
  }
}

.o-grid {
  display: flex;
  flex-wrap: wrap;
  margin: -0.9rem;
}

@media (min-width: 768px) {
  .o-grid {
    margin: -2.6rem;
  }
}

@media (min-width: 768px) {
  .o-grid--medium-2 > .o-grid__col {
    flex: 0 0 auto;
    width: 50%;
  }
}

@media (min-width: 768px) {
  .o-grid--medium-3 > .o-grid__col {
    flex: 0 0 auto;
    width: 33.33%;
  }
}

@media (min-width: 768px) {
  .o-grid--medium-4 > .o-grid__col {
    flex: 0 0 auto;
    width: 25%;
  }
}

@media (min-width: 1024px) {
  .o-grid--large-3 > .o-grid__col {
    flex: 0 0 auto;
    width: 33.33%;
  }
}

@media (min-width: 1024px) {
  .o-grid--large-4 > .o-grid__col {
    flex: 0 0 auto;
    width: 25%;
  }
}

.o-grid__col {
  flex: 0 0 auto;
  width: 100%;
  padding: 0.9rem;
}

@media (min-width: 768px) {
  .o-grid__col {
    padding: 2.6rem;
  }
}

.o-grid__clearfix {
  padding-top: .1px;
  padding-bottom: .1px;
}

.o-input {
  text-transform: uppercase;
  height: 50px;
  border: 1px solid;
  border-radius: 3px;
  padding: 0 22px;
}

.o-link-buttonized {
  text-transform: uppercase;
  font-weight: 600;
  text-decoration: none;
  display: inline-block;
  height: 50px;
  line-height: 50px;
  border: 1px solid #7d99b3;
  border-radius: 25px;
  padding: 0 25px;
  color: #7d99b3;
  transition: background-color 250ms, color 250ms;
}

.o-link-buttonized:hover {
  background-color: #7d99b3;
  color: #fff;
}

.o-list-bare {
  list-style: none;
  margin: 0;
  padding: 0;
}

.o-asymmetric-layout {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

@media (min-width: 768px) {
  .o-asymmetric-layout {
    flex-direction: row;
  }
}

@media (min-width: 768px) {
  .o-asymmetric-layout__main {
    width: 65%;
  }
}

@media (min-width: 1024px) {
  .o-asymmetric-layout__main {
    width: 70%;
  }
}

.o-asymmetric-layout__sidebar {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .o-asymmetric-layout__sidebar {
    width: 25%;
  }
}

@media (min-width: 1024px) {
  .o-asymmetric-layout__sidebar {
    width: 20%;
  }
}

.o-asymmetric-layout__social-share {
  order: 1;
}

@media (min-width: 768px) {
  .o-asymmetric-layout__social-share {
    order: 0;
  }
}

.o-select {
  position: relative;
  width: 100%;
  margin: 10px 0;
}

.o-select__icon {
  width: 10px;
  height: 10px;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: rotate(90deg) translateX(-50%);
  pointer-events: none;
}

.o-select__select {
  appearance: none;
  background-color: transparent;
  width: 100%;
  color: #303437;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  cursor: pointer;
}

.o-select__select::-ms-expand {
  display: none;
}

.o-select__select > option {
  padding: 0;
}

.o-sr-only {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  height: 0;
  width: 0;
  overflow: hidden;
}

.c-about-row {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .c-about-row {
    flex-direction: row;
    margin-right: -2.5em;
    margin-left: -2.5em;
  }
}

@media (min-width: 768px) {
  .c-about-row:nth-child(even) {
    flex-direction: row-reverse;
    margin-bottom: 5rem;
  }
}

.c-about-row:before {
  content: '';
  border-top: 1px solid rgba(125, 153, 179, 0.7);
  width: 100%;
  position: absolute;
  left: 0;
}

@media (min-width: 768px) {
  .c-about-row:before {
    border: none;
  }
}

.c-about-row__column {
  flex: 0 0 auto;
  width: 100%;
  padding: 1em 0;
}

@media (min-width: 768px) {
  .c-about-row__column {
    padding: 2.5rem;
    width: 50%;
  }
}

@media (min-width: 768px) {
  .c-about-row:nth-child(odd) > .c-about-row__column-text {
    padding-bottom: 12.5rem;
  }
}

.c-about-row__text,
.c-about-row__column-image {
  display: none;
}

@media (min-width: 768px) {
  .c-about-row__text,
  .c-about-row__column-image {
    display: block;
  }
}

.c-about-row__text.is-visible,
.c-about-row__column-image.is-visible {
  display: block;
}

.c-about-row__header {
  display: flex;
}

.c-about-row__heading {
  margin-bottom: 0;
  flex: 1 1 auto;
  font-family: "Freight", serif;
  font-size: 3rem;
  font-weight: 600;
}

.c-about-row__button {
  cursor: pointer;
  appearance: none;
  background: none;
  padding: 0;
  border: 0;
  color: inherit;
  height: 4rem;
  padding-left: 5px;
}

@media (min-width: 768px) {
  .c-about-row__button {
    display: none;
  }
}

.c-about-row__button > .c-about-row__button-icon-minus {
  display: none;
}

.c-about-row__button.is-open > .c-about-row__button-icon-plus {
  display: none;
}

.c-about-row__button.is-open > .c-about-row__button-icon-minus {
  display: block;
}

.c-about-row__button-icon {
  width: 15px;
  height: 15px;
}

.c-about-row__text {
  margin-top: 1.5rem;
  list-style-position: inside;
  line-height: 2;
}

.c-about-row__text li {
  margin: 0;
}

.c-about-row__column-image {
  margin-bottom: 3em;
}

@media (min-width: 768px) {
  .c-about-row:nth-child(even) > .c-about-row__column-image {
    margin-top: -15rem;
  }
}

@media (min-width: 768px) {
  .c-about-row__column-image {
    margin-bottom: 0;
  }
}

.c-about-row__image {
  width: 100%;
  height: auto;
}

.c-about__top {
  margin-bottom: 4rem;
}

@media (min-width: 768px) {
  .c-about__top {
    margin-bottom: 7rem;
  }
}

.c-about__mid {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
}

.c-about__bottom {
  background-color: #f5f6f9;
  padding-top: 7rem;
  padding-bottom: 7rem;
  border-top: 1px solid rgba(125, 153, 179, 0.7);
}

.c-about__bottom:after {
  clear: both;
  content: '';
  display: block;
}

@media (min-width: 768px) {
  .c-about__bottom {
    margin-top: 12rem;
    padding-top: 14rem;
    padding-bottom: 14rem;
    border-top: 0;
  }
}

.c-about__bottom-container {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
}

.c-about__bottom-container:after {
  clear: both;
  content: '';
  display: block;
}

@media (min-width: 768px) {
  .c-about__bottom-container {
    flex-direction: row;
  }
}

.c-about__bottom-column {
  flex: 0 0 auto;
  width: 100%;
}

@media (min-width: 768px) {
  .c-about__bottom-column {
    width: 50%;
  }
}

.c-about__bottom-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 3em;
}

@media (min-width: 768px) {
  .c-about__bottom-left {
    margin-bottom: 0;
  }
}

.c-about__bottom-heading {
  font-family: "Freight", serif;
  font-size: 3.2rem;
}

.c-about__bottom-paragraph {
  line-height: 2;
  margin-bottom: 1em;
}

.c-about__bottom-paragraph:last-of-type {
  margin-bottom: 2em;
}

.c-about__bottom-right:after {
  content: '';
  display: block;
  width: 100%;
  height: 45px;
  background: radial-gradient(ellipse at center, rgba(11, 31, 46, 0.2) 0%, transparent 65%);
  margin-bottom: 5px;
}

@media (min-width: 768px) {
  .c-about__bottom-right {
    padding-left: 10rem;
  }
}

.c-about__bottom-link {
  width: 100%;
  text-align: center;
}

@media (min-width: 375px) {
  .c-about__bottom-link {
    width: auto;
  }
}

.c-about__bottom-image {
  display: block;
  width: 100%;
  height: auto;
}

.c-alt-section {
  background: #f5f7f9;
  padding: 4.3rem 0;
}

@media (min-width: 768px) {
  .c-alt-section {
    padding: 9.5rem 0;
  }
}

.c-alt-section__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.5rem;
}

@media (min-width: 768px) {
  .c-alt-section__header {
    margin-bottom: 8rem;
  }
}

.c-alt-section__heading {
  font-size: 1.4rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}

@media (min-width: 768px) {
  .c-alt-section__heading {
    letter-spacing: .5px;
    text-transform: none;
    font-weight: normal;
    font-size: 3.2rem;
    font-family: "Freight", serif;
  }
}

.c-article__title {
  font-weight: normal;
  font-size: 2.3em;
  font-family: "Freight", serif;
  line-height: 1.5em;
  margin-bottom: 1.5em;
}

.c-article__content {
  padding-bottom: 1rem;
  border-bottom: 1px solid #f5f6f9;
}

@media (min-width: 768px) {
  .c-article__content {
    border-bottom: none;
  }
}

.c-author-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 5rem 0;
  padding: 5rem 0;
  width: 100%;
}

@media (min-width: 768px) {
  .c-author-section {
    flex-direction: row;
  }
}

.c-author-section__photo-container {
  position: relative;
  align-self: center;
  order: 2;
}

@media (min-width: 768px) {
  .c-author-section__photo-container {
    order: 1;
    width: 50%;
    max-width: 525px;
    margin-right: 5rem;
  }
}

@media (min-width: 1024px) {
  .c-author-section__photo-container {
    margin-right: 10rem;
  }
}

.c-author-section__photo-container:after {
  content: '';
  display: block;
  width: 100%;
  height: 20px;
  background: radial-gradient(ellipse at center, rgba(11, 31, 46, 0.2) 0%, transparent 65%);
  margin-bottom: 0;
}

.c-author-section__content-container {
  letter-spacing: .5px;
  font-size: 1.3rem;
  line-height: 2.5rem;
  order: 1;
}

@media (min-width: 768px) {
  .c-author-section__content-container {
    order: 2;
    width: 50%;
    align-self: center;
  }
}

.c-author-section__content {
  font-size: 1.4rem;
}

.c-author-section__title {
  text-transform: uppercase;
  font-family: "Freight", serif;
}

@media (min-width: 768px) {
  .c-author-section__title {
    text-transform: none;
    font-size: 3.2rem;
  }
}

.c-author-section__button {
  margin: 3.5rem 0;
}

@media (min-width: 768px) {
  .c-author-section__button {
    margin-top: 5rem;
  }
}

.c-blog-list-single__link {
  text-decoration: none;
}

.c-blog-list-single__image-container {
  width: 100%;
  position: relative;
}

.c-blog-list-single__image-container:after {
  content: '';
  display: block;
  width: 100%;
  height: 45px;
  background: radial-gradient(ellipse at center, rgba(11, 31, 46, 0.2) 0%, transparent 65%);
  margin-bottom: 5px;
}

.c-blog-list-single__image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 45px);
  background-color: rgba(245, 246, 249, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity .25s;
}

.c-blog-list-single__image-overlay:hover {
  opacity: 1;
}

.c-blog-list-single__image {
  width: 100%;
}

.c-blog-list-single__heading {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1em;
  line-height: 2;
  margin-bottom: 0;
}

.c-blog-list-single__footer {
  line-height: 2;
}

.c-blog-list__list {
  background-color: #f5f6f9;
  margin-top: 3rem;
  padding-top: 6rem;
}

.c-blog-list__pages {
  display: flex;
  justify-content: center;
  padding: 48px 0;
  border-top: 1px solid #e6ebf0;
  margin-top: 5rem;
}

.c-blog-list__pages:after {
  clear: both;
  content: '';
  display: block;
}

.c-blog-list__pages-col {
  display: block;
  float: left;
  line-height: 1em;
  padding: .5em;
  width: 2em;
  border: 1px solid transparent;
  text-decoration: none;
  text-align: center;
  color: #bec3c8;
  margin-right: 1em;
}

.c-blog-list__pages-col:last-child {
  margin-right: 0;
}

.c-blog-list__pages-col.current {
  border: 1px solid #75818e;
  color: #303437;
}

.c-blog-list__pages-col-label {
  text-transform: uppercase;
  width: auto;
  color: #303437;
}

.c-blog-list__pages-col-icon {
  width: 10px;
  height: 10px;
  fill: #75818e;
}

.c-blog-list__list-not-found {
  text-align: center;
}

/* stylelint-disable */
/* stylelint-enable */
.c-blog-single__container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.c-blog-single__container:after {
  clear: both;
  content: '';
  display: block;
}

.c-blog-single__main {
  width: 100%;
  float: left;
}

@media (min-width: 768px) {
  .c-blog-single__main {
    width: calc(100% - 220px);
    padding-right: 5%;
  }
}

@media (min-width: 1024px) {
  .c-blog-single__main {
    padding-right: 10%;
  }
}

.c-blog-single__sidebar {
  width: 100%;
  float: left;
  margin-top: 2em;
}

@media (min-width: 768px) {
  .c-blog-single__sidebar {
    margin-top: 0;
    width: 220px;
  }
}

.c-blog-single__datecat {
  text-transform: uppercase;
  margin-bottom: 1.7em;
  font-weight: 600;
}

.c-blog-single__datecat-date {
  color: #bec3c8;
}

.c-blog-single__datecat-separator {
  padding: 0 10px;
}

.c-blog-single__datecat-category {
  text-decoration: none;
  color: #7d99b3;
}

.c-blog-single__title {
  font-weight: 600;
  font-size: 2.3em;
  font-family: "Freight", serif;
  line-height: 1.5em;
  margin-bottom: 1.5em;
}

.c-blog-single__share-label {
  text-transform: uppercase;
  padding-right: 10px;
  float: left;
  font-weight: 600;
}

.c-blog-single__share-link {
  text-decoration: none;
  padding: 0 10px;
  float: left;
}

.c-blog-single__share-icon {
  height: 16px;
  width: 16px;
  fill: #7d99b3;
  stroke: #7d99b3;
}

.c-blog-single__more-list {
  background-color: #f5f6f9;
  margin-top: 4rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

@media (min-width: 768px) {
  .c-blog-single__more-list {
    margin-top: 8rem;
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
}

.c-blog-single__more-header {
  display: flex;
}

.c-blog-single__more-heading {
  margin: 0;
  line-height: normal;
  flex: 1 0;
  font-weight: 600;
  font-family: "Freight", serif;
  font-size: 2.3em;
  margin-bottom: 4rem;
}

@media (min-width: 768px) {
  .c-blog-single__more-heading {
    margin-bottom: 8rem;
  }
}

.c-blog-single__content {
  /**
   * 8.0 Alignments
   */
  font-weight: 400;
}

.c-blog-single__content .alignleft {
  display: inline;
  float: left;
}

.c-blog-single__content .alignright {
  display: inline;
  float: right;
}

.c-blog-single__content .aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.c-blog-single__content blockquote.alignleft,
.c-blog-single__content .wp-caption.alignleft,
.c-blog-single__content img.alignleft {
  margin: 0.4em 1.6em 1.6em 0;
}

.c-blog-single__content blockquote.alignright,
.c-blog-single__content .wp-caption.alignright,
.c-blog-single__content img.alignright {
  margin: 0.4em 0 1.6em 1.6em;
}

.c-blog-single__content blockquote.aligncenter,
.c-blog-single__content .wp-caption.aligncenter,
.c-blog-single__content img.aligncenter {
  clear: both;
  margin-top: 0.4em;
  margin-bottom: 1.6em;
}

.c-blog-single__content .wp-caption.alignleft,
.c-blog-single__content .wp-caption.alignright,
.c-blog-single__content .wp-caption.aligncenter {
  margin-bottom: 1.2em;
}

.c-blog-single__content p {
  margin-bottom: 2em;
}

.c-blog-single__content img {
  max-width: 100%;
  height: auto;
}

.c-blog-single__content .wp-caption {
  max-width: 100%;
}

.c-blog-single__content .wp-caption > .wp-caption-text {
  padding-top: 27px;
  padding-bottom: 17px;
  border-bottom: 1px solid #e6ebf0;
  font-weight: normal;
  font-size: .9em;
  line-height: normal;
}

.c-callout {
  position: relative;
  min-width: 100%;
  margin: 0 -15px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

@media (min-width: 375px) {
  .c-callout {
    margin-left: -35px;
    margin-right: -35px;
  }
}

@media (min-width: 768px) {
  .c-callout {
    display: flex;
    margin-left: -2.5rem;
    margin-right: -2.5rem;
    border-top: 0;
    border-bottom: 0;
  }
}

.c-callout--right {
  flex-direction: row-reverse;
}

@media (min-width: 768px) {
  .c-callout__media,
  .c-callout__content {
    margin: 0 2.5rem;
  }
}

.c-callout__media {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-width: 768px) {
  .c-callout__media {
    position: static;
    flex: 1 1 auto;
  }
}

.c-callout__media-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.c-callout__content {
  position: relative;
  padding: 5rem 15px;
  background: rgba(255, 255, 255, 0.9);
}

@media (min-width: 375px) {
  .c-callout__content {
    padding-left: 35px;
    padding-right: 35px;
  }
}

@media (min-width: 768px) {
  .c-callout__content {
    width: 300px;
    flex: 1 0 auto;
    padding: 0;
  }
}

.c-callout__icon {
  height: 1em;
  width: 1em;
  font-size: 7rem;
  margin-bottom: 5rem;
}

.c-callout__heading {
  margin-bottom: 3rem;
  font-weight: bold;
  color: #7d99b3;
}

@media (min-width: 768px) {
  .c-callout__heading {
    color: #000;
  }
}

.c-careers__top {
  margin-bottom: 6rem;
}

@media (min-width: 768px) {
  .c-careers__top {
    margin-bottom: 12.5rem;
  }
}

.c-careers__carousel {
  margin-bottom: 6rem;
}

@media (min-width: 768px) {
  .c-careers__carousel {
    margin-bottom: 13rem;
  }
}

.c-careers__below-carousel-container {
  max-width: 1160px;
}

.c-careers__info {
  margin: 5rem 0;
}

@media (min-width: 768px) {
  .c-careers__info {
    margin: 10rem 0;
  }
}

.c-careers__info-single-heading {
  font-family: "Freight", serif;
  font-size: 3.2rem;
}

.c-careers__info-single-content {
  list-style-position: inside;
}

.c-careers__culinary-agents {
  margin-bottom: 7rem;
}

@media (min-width: 768px) {
  .c-careers__culinary-agents {
    margin-bottom: 15rem;
  }
}

.c-carousel {
  width: 100%;
  overflow: auto;
  position: relative;
  -webkit-overflow-scrolling: touch;
}

@media (min-width: 768px) {
  .c-carousel {
    overflow: hidden;
  }
}

.c-carousel__container {
  position: relative;
  display: flex;
  padding-left: 0;
  padding-right: 0;
  transition: transform .5s;
}

@media (max-width: 374px) {
  .c-carousel__container {
    transform: none !important;
  }
}

@media (min-width: 375px) and (max-width: 767px) {
  .c-carousel__container {
    transform: none !important;
  }
}

.c-carousel__item.c-carousel__item {
  max-width: none;
  flex: 1 0 auto;
  align-self: stretch;
  border-left: 5px solid #fff;
  border-right: 5px solid #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (min-width: 768px) {
  .c-carousel__item.c-carousel__item {
    border-left-width: 17.5px;
    border-right-width: 17.5px;
  }
}

.c-carousel__item.c-carousel__item:first-child {
  border-left-width: 0;
}

@media (min-width: 768px) {
  .c-carousel__item.c-carousel__item:first-child {
    border-left-width: 35px;
  }
}

.c-carousel__item.c-carousel__item:last-child {
  border-right-width: 0;
}

@media (min-width: 768px) {
  .c-carousel__item.c-carousel__item:last-child {
    border-right-width: 35px;
  }
}

.c-carousel__photo {
  max-width: 100vw;
}

.c-carousel__img {
  display: block;
  height: 450px;
  max-width: 100vw;
  max-height: calc(100vh - 30px);
  object-fit: cover;
}

.c-carousel__text {
  padding: 10px 35px;
}

@media (min-width: 768px) {
  .c-carousel__text {
    padding: 10px 0;
  }
}

.c-carousel__prev {
  display: none;
}

@media (min-width: 768px) {
  .c-carousel__prev {
    display: block;
    left: 50px;
  }
}

.c-carousel__next {
  display: none;
}

@media (min-width: 768px) {
  .c-carousel__next {
    display: block;
    right: 50px;
  }
}

.c-covers-carousel {
  position: relative;
  flex: 1 0 auto;
  margin: 0 -26px;
}

.c-covers-carousel__photo {
  position: relative;
}

.c-covers-carousel__photo::after {
  content: '';
  position: absolute;
  z-index: -1;
  display: block;
  height: 30px;
  top: calc(100% - 2px);
  left: 10px;
  right: 10px;
  border-radius: 50%;
  background: radial-gradient(ellipse at center, rgba(11, 31, 46, 0.2) 0%, transparent 65%);
}

.c-covers-carousel__text {
  margin-top: 3rem;
  text-align: center;
  font-weight: bold;
}

@media (min-width: 768px) {
  .c-covers-carousel__text {
    margin-top: 5rem;
  }
}

.c-covers-carousel__heading {
  font-weight: bold;
}

.c-covers-carousel__heading--align-left {
  text-align: left;
}

.c-covers-carousel__item {
  max-width: 363px;
  padding: 0 26px;
}

.c-covers-carousel__item--auto-width {
  max-width: none;
  flex-grow: 0;
  flex-basis: initial;
}

.c-default-carousel {
  position: relative;
  width: 100%;
  max-width: 1400px;
  margin: 7rem auto 0;
}

@media (min-width: 768px) {
  .c-default-carousel {
    width: calc(100% - 100px);
    margin-bottom: 16rem;
  }
}

@media (min-width: 768px) {
  .c-default-carousel--pale-bg::before {
    content: '';
    position: absolute;
    z-index: -1;
    left: 50%;
    width: calc(100vw);
    top: 6rem;
    bottom: 6rem;
    transform: translateX(-50%);
    background: #f5f7f9;
  }
}

.c-default-carousel__item {
  background-size: cover;
  height: 78vw;
  max-height: 900px;
  max-width: 100%;
}

.c-default-carousel__item-img {
  display: none;
}

.c-filter-bar {
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .c-filter-bar {
    flex-direction: row;
  }
}

.c-filter-bar__column {
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .c-filter-bar__column {
    border: 0;
    border-left: 1px solid #e6ebf0;
    padding: 0 30px;
    margin-top: 0;
  }
}

.c-filter-bar__column:first-child {
  padding-left: 0;
  border-left: none;
  margin-top: 0;
}

.c-filter-bar__column--horizontal {
  display: none;
}

@media (min-width: 768px) {
  .c-filter-bar__column--horizontal {
    display: flex;
  }
}

@media (min-width: 768px) {
  .c-filter-bar__select-icon {
    right: 0;
  }
}

@media (min-width: 768px) {
  .c-filter-bar__select {
    padding: 0;
    border: 0;
    width: 150px;
  }
}

.c-filter-bar__label {
  color: #bec3c8;
}

.c-filter-bar__spacer {
  flex: 1 1;
  padding: 0;
}

.c-filter-bar__search {
  position: relative;
  width: 100%;
}

.c-filter-bar__search-input {
  position: relative;
  top: -3px;
  width: calc(100% - 21px);
  padding: 0;
  border: 0;
  letter-spacing: 1px;
  -webkit-font-smoothing: subpixel-antialiased;
  font-weight: 600;
}

@media (min-width: 768px) {
  .c-filter-bar__search-input {
    width: 100px;
  }
}

@media (min-width: 1024px) {
  .c-filter-bar__search-input {
    width: 250px;
  }
}

.c-filter-bar__search-input::placeholder {
  color: #303437;
  text-transform: uppercase;
  font-weight: 600;
  opacity: 1;
}

.c-filter-bar__search-button {
  cursor: pointer;
  appearance: none;
  background: none;
  padding: 0;
  border: 0;
  color: inherit;
  width: 17px;
  height: 17px;
  cursor: pointer;
  position: relative;
  top: 3px;
}

.c-filter-bar__search-icon {
  width: 100%;
  height: 100%;
}

.c-filter-list {
  display: flex;
  flex-grow: 1;
  max-width: 620px;
  margin: 0 30px 0 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  justify-content: space-between;
}

@media (min-width: 1024px) {
  .c-filter-list {
    margin: 0 30px;
  }
}

.c-filter-list__label {
  color: #303437;
  font-weight: bold;
  white-space: nowrap;
}

.c-filter-list__label:after {
  content: ':';
}

@media (min-width: 1024px) {
  .c-filter-list__label {
    margin-right: 20px;
  }
}

.c-filter-list__option {
  color: #bec3c8;
  letter-spacing: 0;
  cursor: pointer;
  transition: color .25s;
}

.c-filter-list__option:hover, .c-filter-list__option.active {
  color: #303437;
}

@media (min-width: 1024px) {
  .c-filter-list__option {
    letter-spacing: 1px;
  }
}

.c-footer {
  margin-top: 7.5rem;
  margin-bottom: 4rem;
  font-size: 1.3rem;
}

@media (min-width: 768px) {
  .c-footer {
    display: flex;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .c-footer__contact {
    padding-right: 14rem;
  }
}

.c-footer__contact-title {
  font-size: 1.3rem;
  font-weight: 600;
  font-family: "DIN", helvetica, sans-serif;
  margin-bottom: 1.5em;
}

.c-footer__contact-item {
  display: block;
  text-decoration: none;
  margin: 0;
  line-height: 2;
}

.c-footer__contact-item--address-line-2 {
  margin-bottom: 1.5em;
}

.c-footer__list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 2rem 0 1rem;
}

@media (min-width: 768px) {
  .c-footer__list {
    margin-left: 0;
    margin-right: 0;
  }
}

.c-footer__list-item {
  margin: 0;
  font-weight: bold;
  text-transform: uppercase;
}

.c-footer__list-item > a {
  text-decoration: none;
}

.c-footer__list-item > a:hover {
  text-decoration: underline;
}

.c-footer__list-item:first-child .c-footer__list-link {
  padding-left: 0;
}

.c-footer__list-link {
  display: block;
  padding: .5rem 1rem;
  font-weight: 600;
}

@media (min-width: 768px) {
  .c-footer__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.c-footer__content {
  flex: 1;
}

.c-footer__newsletter-form {
  margin: 3rem 0;
  display: block;
}

.c-footer__copyright {
  padding: .5rem 0;
  color: #bec3c8;
}

.c-footer__warning {
  border-top: 1px solid #ECEFF7;
  padding-top: 18px;
}

.c-footer__warning p {
  display: block;
  width: 100%;
  max-width: 890px;
}

.c-footer__warning p a {
  display: unset;
}

.c-form-element {
  position: relative;
  border: 1px solid #eceff7;
  border-radius: 4px;
  font-size: 1.6rem;
}

@media (min-width: 768px) {
  .c-form-element {
    font-size: 2.4rem;
  }
}

.c-form-element--with-btn .c-form-element__input {
  padding-right: 8rem;
}

@media (min-width: 768px) {
  .c-form-element--with-btn .c-form-element__input {
    padding-right: 10rem;
  }
}

.c-form-element__input {
  width: 100%;
  padding: 1.8rem;
  appearance: none;
  background: none;
  border: 0;
}

@media (min-width: 768px) {
  .c-form-element__input {
    padding: 2.5rem;
  }
}

.c-form-element__btn {
  position: absolute;
  right: 0;
  left: auto;
  top: 50%;
  padding: 2.5rem;
  line-height: 2.4rem;
  font-size: 1.3rem;
  font-weight: normal;
  text-transform: uppercase;
  color: #303437;
  transform: translateY(-50%);
  transition: color .1s;
}

.c-form-element__btn[disabled] {
  color: #d9dce4;
  cursor: default;
}

.c-header {
  position: relative;
  padding-top: 3rem;
  width: 100%;
  z-index: 5;
}

@media (min-width: 768px) {
  .c-header {
    padding-top: 6rem;
  }
}

.c-header.is-nav-open {
  position: fixed;
  top: 0;
}

.c-header--light:not(.is-nav-open) {
  color: #fff;
  fill: #fff;
}

.c-header--light:not(.is-nav-open) .c-header__logo-img {
  background-image: url("../assets/images/logo-white.svg");
}

.c-header--fixed {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

.c-header > .o-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.c-header__logo {
  display: block;
  width: 160px;
  transition: opacity .25s;
  opacity: .7;
}

.c-header__logo:hover {
  opacity: 1;
}

.c-header__logo-img {
  display: block;
  width: 160px;
  height: 64px;
  background: center center no-repeat;
  background-image: url("../assets/images/logo.svg");
  background-size: contain;
}

.c-header__menu-toggle {
  cursor: pointer;
  appearance: none;
  background: none;
  padding: 0;
  border: 0;
  color: inherit;
  font-size: 56px;
  min-width: 56px;
  text-align: center;
}

.c-header__menu-toggle.is-nav-open .c-header__menu-icon {
  display: none;
}

.c-header__menu-toggle.is-nav-open .c-header__menu-icon--close {
  display: block;
}

.c-header__menu-icon {
  display: block;
  fill: currentColor;
  width: 1em;
  height: .55em;
  margin: 0 auto;
}

.c-header__menu-icon--close {
  width: .8em;
  height: .8em;
  display: none;
  transform: rotate(45deg);
}

.c-hero {
  position: relative;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  color: inherit;
}

.c-hero__lory-frame {
  min-height: 100vh;
}

.c-hero__lory-container {
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.c-hero__lory-slide {
  position: relative;
  min-height: 100vh;
}

.c-hero__lory-prev,
.c-hero__lory-next {
  transform: none;
  top: auto;
  bottom: 0;
}

.c-hero__bg {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.c-hero__bg-image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: .7;
}

@media (min-width: 768px) {
  .c-hero__bg-image {
    opacity: 1;
  }
}

.c-hero__container {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100vw;
  min-height: inherit;
}

.c-hero__content {
  max-width: 600px;
}

.c-hero__content--centered {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  text-align: center;
}

.c-hero__overlay-image {
  display: block;
  margin: auto;
}

.c-hero__overlay-image ~ * {
  display: none;
}

@media (min-width: 768px) {
  .c-hero__overlay-image {
    position: absolute;
    left: 20px;
    top: 20px;
  }
}

.c-hero__central-image {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 364px;
  max-width: 100%;
  margin-bottom: 30px;
}

.c-hero__subtitle {
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
}

.c-hero__title {
  font-family: "Freight", serif;
  font-size: 2.6rem;
  letter-spacing: .5px;
  font-weight: 300;
}

@media (min-width: 768px) {
  .c-hero__title {
    font-size: 3.2rem;
  }
}

.c-hero__subtitle--inverted-color,
.c-hero__title--inverted-color,
.c-hero__btn--inverted-color {
  color: #fff;
}

.c-hero__subtitle--inverted-color:hover,
.c-hero__title--inverted-color:hover,
.c-hero__btn--inverted-color:hover {
  color: #fff;
}

.c-hero__btn:hover {
  opacity: .6;
}

.c-hero__btn--inverted-color:hover {
  color: #e6ebf0;
  opacity: 1;
}

.c-hero__back-link {
  display: block;
  position: relative;
  width: 200px;
  height: 8px;
  color: #fff;
  cursor: pointer;
}

.c-hero__back-link > svg {
  display: block;
  position: absolute;
  top: 50%;
  height: 40px;
  width: 50px;
  transform: translateY(-50%);
}

.c-hero__back-link:hover {
  color: #fff;
}

.c-hero__back-link--not-clickable {
  cursor: default;
}

.c-hero__forward-link {
  position: relative;
  padding: 5px 25px 5px 5px;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  width: 200px;
  text-align: right;
  font-weight: bold;
}

.c-hero__forward-link:hover {
  color: #fff;
}

.c-hero__forward-link:after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  width: 0;
  height: 0;
  border-left: 8px solid #fff;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  transform: translateY(-50%);
}

.c-hero__scroll-icon {
  position: absolute;
  left: 50%;
  bottom: 2.5%;
  width: 50px;
  height: 40px;
  color: #fff;
  transform: translateX(-50%);
  cursor: pointer;
}

@media (min-width: 768px) {
  .c-hero__scroll-icon {
    bottom: 7.5%;
    width: 80px;
  }
}

.c-hero__scroll-icon > svg {
  display: block;
  width: inherit;
  height: inherit;
}

.c-icon__swipe {
  margin: 20px auto;
  width: 170px;
  height: 20px;
  fill: #b9c7d6;
  opacity: .4;
}

.c-intro {
  max-width: 950px;
}

@media (min-width: 768px) {
  .c-intro {
    margin: 2rem auto 10rem;
    text-align: center;
  }
}

.c-intro__text {
  max-width: 770px;
  margin: 0 auto;
}

.c-intro__heading {
  font-family: "Freight", serif;
  font-size: 2.5rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

@media (min-width: 768px) {
  .c-intro__heading {
    font-size: 3.2rem;
    letter-spacing: 4px;
  }
}

.c-landing {
  width: 100%;
  height: 450px;
  max-height: 100vh;
  overflow: hidden;
  background: center center no-repeat;
  background-size: cover;
}

@media (min-width: 768px) {
  .c-landing {
    height: 800px;
  }
}

.c-landing__img {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
  visibility: hidden;
}

.c-masonry-grid__column-sizer {
  display: block;
  height: 0;
  width: calc(50% - 10px);
}

.c-masonry-grid__gutter-sizer {
  display: block;
  height: 0;
  width: 20px;
}

.c-nav {
  display: none;
  position: fixed;
  z-index: 3;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  padding: 15rem 0 2rem;
  overflow: auto;
  background: rgba(255, 255, 255, 0.96);
}

@media (min-width: 768px) {
  .c-nav {
    align-items: center;
    justify-content: center;
    padding: 15rem 2rem 5rem;
    text-align: center;
  }
}

.c-nav.is-open {
  display: block;
}

@media (min-width: 768px) {
  .c-nav.is-open {
    display: flex;
  }
}

.c-nav__list {
  width: 100%;
  margin: 0;
  list-style: none;
}

@media (min-width: 768px) {
  .c-nav__list {
    width: auto;
  }
}

.c-nav__list-item {
  text-transform: uppercase;
  font-family: "DIN", helvetica, sans-serif;
  font-weight: 500;
  font-size: 1.8rem;
  letter-spacing: 3px;
  border: 1px solid rgba(125, 153, 179, 0.3);
  border-width: 1px 0 0 0;
  margin: 0;
  transition: opacity .25s;
  opacity: .7;
}

@media (min-width: 768px) {
  .c-nav__list-item {
    font-family: "Freight", serif;
    font-size: 3.2rem;
    letter-spacing: 5.3px;
    margin-bottom: 3rem;
    border: none;
  }
}

.c-nav__list-item:last-child {
  border-width: 1px 0;
}

.c-nav__list-item:hover {
  opacity: 1;
}

.c-nav__list-item-link {
  display: block;
  text-decoration: none;
  padding: 2rem 15px;
}

@media (min-width: 375px) {
  .c-nav__list-item-link {
    padding: 2rem 35px;
  }
}

@media (min-width: 768px) {
  .c-nav__list-item-link {
    padding: 0;
  }
}

.c-nav__sublist {
  display: none;
  margin: 0;
  list-style: none;
}

@media (min-width: 768px) {
  .c-nav__sublist {
    display: block;
  }
}

.c-nav__sublist-item {
  display: block;
  text-decoration: none;
  transition: opacity .25s;
  opacity: .7;
}

.c-nav__sublist-item:hover {
  opacity: 1;
}

.c-nav__sublist-item-link {
  display: block;
  text-decoration: none;
  color: #7d99b3;
}

.c-nav__sublist-item-link:hover {
  color: #7d99b3;
}

.c-photo-carousel__header {
  margin: 5rem 0;
  font-family: "Freight", serif;
  font-weight: 400;
  font-size: 3.2rem;
  letter-spacing: .5px;
}

.c-carousel__photo:after {
  content: '';
  display: block;
  width: 100%;
  height: 20px;
  background: radial-gradient(ellipse at center, rgba(11, 31, 46, 0.2) 0%, transparent 65%);
  margin-bottom: 0;
}

.c-carousel__photo:after {
  opacity: .7;
}

.c-press-carousel-single {
  width: 100%;
}

@media (min-width: 768px) {
  .c-press-carousel-single:after {
    content: '';
    display: block;
    width: 100%;
    height: 60px;
    background: radial-gradient(ellipse closest-side at 50% 65%, rgba(11, 31, 46, 0.2) 0%, transparent 85%);
  }
}

@media (min-width: 768px) {
  .c-press-carousel-single__container {
    padding-bottom: 52%;
    position: relative;
  }
}

@media (min-width: 768px) {
  .c-press-carousel-single__content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.c-press-carousel-single__column {
  height: 100%;
}

@media (min-width: 768px) {
  .c-press-carousel-single__column {
    width: 50%;
    float: left;
  }
}

.c-press-carousel-single__left {
  overflow: hidden;
}

.c-press-carousel-single__image {
  width: 100%;
}

.c-press-carousel-single__right {
  padding: 5%;
  display: flex;
  flex-direction: column;
  background-color: #f5f6f9;
}

.c-press-carousel-single__right-top {
  text-transform: uppercase;
  font-weight: 600;
}

.c-press-carousel-single__right-bottom {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.c-press-carousel-single__heading {
  font-weight: 600;
  font-size: 3rem;
  font-family: "Freight", serif;
  margin-bottom: 1.4rem;
}

.c-press-carousel-single__source {
  letter-spacing: 1.5px;
  font-weight: 600;
  margin-bottom: 2rem;
}

.c-press-carousel-single__excerpt {
  margin-bottom: 4rem;
  overflow: hidden;
}

@media (min-width: 1024px) {
  .c-press-single__container {
    padding-bottom: 62%;
    position: relative;
  }
}

@media (min-width: 1024px) {
  .c-press-single__content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.c-press-single__column {
  background-color: #fff;
  width: 100%;
  height: 100%;
  float: left;
}

@media (min-width: 1024px) {
  .c-press-single__column {
    width: 50%;
  }
}

.c-press-single__left {
  overflow: hidden;
}

.c-press-single__image {
  width: 100%;
}

.c-press-single__right {
  padding: 3% 5%;
  display: flex;
  flex-direction: column;
}

.c-press-single__right-top {
  text-transform: uppercase;
  font-weight: 600;
}

.c-press-single__right-center {
  padding-top: 1em;
  flex-grow: 1;
  overflow: hidden;
}

@media (min-width: 1024px) {
  .c-press-single__right-center {
    padding-top: 5em;
  }
}

.c-press-single__heading {
  font-family: "Freight", serif;
  margin-bottom: .8em;
  font-weight: 600;
}

.c-press-single__excerpt {
  line-height: 1.7;
}

.c-press-single__link {
  display: inline-block;
  color: #7d99b3;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;
  padding-bottom: 1em;
  border-bottom: 1px solid #7d99b3;
}

.c-press__intro {
  max-width: 900px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  margin-bottom: 8rem;
}

@media (min-width: 768px) {
  .c-press__intro {
    margin-bottom: 16rem;
  }
}

.c-press__intro-heading {
  font-family: "Freight", serif;
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: .8em;
  letter-spacing: 1px;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .c-press__intro-heading {
    line-height: 1.5em;
    letter-spacing: 4px;
  }
}

.c-press__intro-paragraph {
  max-width: 770px;
  margin-right: auto;
  margin-left: auto;
  letter-spacing: .5px;
}

.c-press__carousel {
  position: relative;
  margin-bottom: 4rem;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .c-press__carousel {
    margin-bottom: 10rem;
  }
}

.c-press__carousel-container {
  width: 100%;
}

.c-press__carousel-prev,
.c-press__carousel-next {
  top: 230px;
  background: rgba(255, 255, 255, 0.25);
  transform: none;
}

@media (min-width: 768px) {
  .c-press__carousel-prev,
  .c-press__carousel-next {
    top: 50%;
    background: none;
    transform: translateY(-50%);
  }
}

.c-press__carousel-prev {
  left: 20px;
}

@media (min-width: 768px) {
  .c-press__carousel-prev {
    left: -1rem;
  }
}

@media (min-width: 1120px) {
  .c-press__carousel-prev {
    left: calc((1100px - 100vw) / 2);
  }
}

@media (min-width: 1368px) {
  .c-press__carousel-prev {
    left: -134px;
  }
}

.c-press__carousel-next {
  right: 20px;
}

@media (min-width: 768px) {
  .c-press__carousel-next {
    right: -1rem;
  }
}

@media (min-width: 1120px) {
  .c-press__carousel-next {
    right: calc((1100px - 100vw) / 2);
  }
}

@media (min-width: 1368px) {
  .c-press__carousel-next {
    right: -134px;
  }
}

.c-press__filter-bar {
  border-top: 1px solid #e6ebf0;
  padding-top: 2.9rem;
  margin-bottom: 2em;
}

.c-press__list {
  background-color: #f5f6f9;
  padding-top: 6rem;
}

.c-press__list-not-found {
  text-align: center;
}

.c-press__more {
  padding: 48px 0;
  border-top: 1px solid #e6ebf0;
  margin-top: 5rem;
  text-align: center;
}

.c-press__more-link {
  text-decoration: none;
  text-transform: uppercase;
  color: #bec3c8;
}

.c-press__more-link.is-hidden {
  display: none;
}

.c-sidebar__section {
  margin-bottom: 2em;
}

.c-sidebar__section--with-border {
  border-bottom: 1px solid #e6ebf0;
}

.c-sidebar__section p {
  margin: 0;
}

.c-sidebar__section-header {
  text-transform: uppercase;
  font-size: 1em;
  font-weight: 600;
  letter-spacing: 1px;
}

.c-sidebar__subscribe {
  display: block;
  text-align: center;
}

.c-sidebar__visit {
  display: block;
  text-align: center;
  margin: 2em 0 3em;
}

.c-sidebar__link {
  text-decoration: none;
  color: #7d99b3;
}

.c-sidebar__select {
  width: 100%;
  border: 0;
  padding: 0;
}

.c-sidebar__search {
  position: relative;
  width: 100%;
  height: 40px;
}

.c-sidebar__search-input {
  position: relative;
  top: -3px;
  width: calc(100% - 41px);
  padding: 0;
  border: 0;
  letter-spacing: 1px;
  -webkit-font-smoothing: subpixel-antialiased;
}

.c-sidebar__search-input::placeholder {
  color: #303437;
  text-transform: uppercase;
  font-weight: 600;
  opacity: 1;
}

.c-sidebar__search-button {
  cursor: pointer;
  appearance: none;
  background: none;
  padding: 0;
  border: 0;
  color: inherit;
  width: 17px;
  height: 17px;
  cursor: pointer;
  position: relative;
  top: 3px;
}

.c-sidebar__search-icon {
  width: 100%;
  height: 100%;
}

.c-social-share {
  display: flex;
  margin: 0;
}

@media (min-width: 768px) {
  .c-social-share {
    margin: 0 0 4rem;
  }
}

.c-social-share--footer {
  margin: 2rem 0;
}

.c-social-share__item {
  list-style: none;
  margin: 0 2rem 0 0;
}

.c-social-share__link {
  display: block;
}

.c-social-share__icon {
  width: 20px;
  height: 20px;
  fill: #7d99b3;
}

.c-social-share__icon--instagram {
  margin-top: 1px;
  height: 18px;
}

.c-social-snippets {
  margin: 5rem 0 2rem;
}

@media (min-width: 768px) {
  .c-social-snippets {
    margin: 15rem -2.5rem;
  }
}

@media (min-width: 768px) {
  .c-social-snippets .o-lory__dots {
    display: none;
  }
}

.c-social-snippets__item {
  position: relative;
  flex: 1 0 100%;
  padding: 0 1rem;
}

@media (min-width: 768px) {
  .c-social-snippets__item {
    padding-left: 4.5rem;
    margin: 0 2.5rem;
    flex: 1 1 auto;
  }
}

.c-social-snippets__title {
  font-weight: bold;
}

.c-social-snippets__text {
  margin: 2rem 0 3rem;
}

.c-social-snippets__icon {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  height: 1em;
  width: 1em;
  font-size: 1.4em;
  color: #7d99b3;
}

@media (min-width: 768px) {
  .c-social-snippets__icon {
    display: block;
  }
}

.c-team-members-founders {
  display: flex;
  height: 100%;
  position: relative;
  flex-direction: column;
}

@media (min-width: 768px) {
  .c-team-members-founders {
    flex-direction: row;
  }
}

.c-team-members-founders__side {
  flex: 0 0 auto;
  padding: 1em 0;
  background-color: #f5f6f9;
  display: flex;
  position: relative;
}

@media (min-width: 768px) {
  .c-team-members-founders__side {
    flex: 0 0 28%;
    padding: 0;
    margin: 10% 0;
  }
}

.c-team-members-founders__middle {
  flex: 0 0 auto;
  position: relative;
  order: -1;
}

@media (min-width: 768px) {
  .c-team-members-founders__middle {
    flex: 0 0 44%;
    order: 0;
  }
}

.c-team-members-founders__middle.has-overlay {
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-width: 768px) {
  .c-team-members-founders__middle.has-overlay {
    position: relative;
  }
}

.c-team-members-founders__middle-default {
  height: 100%;
  overflow: hidden;
}

.c-team-members-founders__middle-image {
  width: 100%;
  height: auto;
}

.c-team-members-founders__person {
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

@media (min-width: 1024px) {
  .c-team-members-founders__person {
    padding: 3rem 6rem;
  }
}

.c-team-members-founders__person-name,
.c-team-members-founders__middle-person-heading {
  font-family: "Freight", serif;
  font-weight: 600;
  font-size: 3.2rem;
}

.c-team-members-founders__person-info {
  margin-bottom: 2em;
}

.c-team-members-founders__hidden {
  display: none;
}

.c-team-members-founders__hidden.is-visible {
  display: block;
}

.c-team-members-founders__absolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-team-members-founders__side-image {
  overflow: hidden;
}

.c-team-members-founders__side-image-image {
  width: 100%;
  height: auto;
}

.c-team-members-founders__middle-person {
  background-color: rgba(245, 246, 249, 0.95);
  padding: 3rem;
  flex-direction: column;
}

.c-team-members-founders__middle-person.is-visible {
  display: flex;
}

.c-team-members-founders__middle-person-content {
  margin-top: 1em;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

@media (min-width: 768px) {
  .c-team-members-founders__middle-person-content {
    padding: 2em;
  }
}

.c-team-members-founders__middle-close-row {
  text-align: right;
}

.c-team-members-founders__middle-close {
  cursor: pointer;
  appearance: none;
  background: none;
  padding: 0;
  border: 0;
  color: inherit;
  text-transform: uppercase;
  color: #7d99b3;
  font-weight: 600;
  padding-bottom: 1em;
  border-bottom: 1px solid #7d99b3;
}

.c-team-members-member__image {
  width: 100%;
  height: auto;
}

.c-team-members-member__name {
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 2;
  margin-top: 1.8rem;
  margin-bottom: 0;
}

.c-team-members-member__position {
  font-size: 1.4rem;
  line-height: 2;
  margin-bottom: 1em;
}

.c-team-members-member__desc {
  line-height: 1.7;
  margin-bottom: 0;
}

.c-team-members__members-section {
  margin-bottom: 7rem;
}

@media (min-width: 768px) {
  .c-team-members__members-section {
    margin-bottom: 14rem;
  }
}

.c-team-members__members-header {
  margin-bottom: 7rem;
}

@media (min-width: 768px) {
  .c-team-members__members-header {
    margin-bottom: 13rem;
  }
}

.c-team-members__top {
  margin-bottom: 5rem;
}

@media (min-width: 768px) {
  .c-team-members__top {
    margin-bottom: 10rem;
  }
}

.c-team-members__founders {
  margin-bottom: 7rem;
}

@media (min-width: 768px) {
  .c-team-members__founders {
    margin-bottom: 14rem;
    padding-bottom: 56%;
    position: relative;
  }
}

@media (min-width: 768px) {
  .c-team-members__founders-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.c-team-members__grids {
  max-width: 1400px;
}

.c-thumbnail-carousel {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}

.c-thumbnail-carousel__frame {
  height: 400px;
  flex-grow: 1;
  transition: height .25s;
}

@media (min-width: 768px) {
  .c-thumbnail-carousel__frame {
    height: 800px;
  }
}

.c-thumbnail-carousel__container {
  display: flex;
  height: 100%;
}

.c-thumbnail-carousel__item {
  position: relative;
  flex: 1 0 100%;
  height: 100%;
  background: center center no-repeat;
  background-size: cover;
}

.c-thumbnail-carousel__item-img {
  display: block;
  object-fit: cover;
  height: 400px;
  visibility: hidden;
}

.c-thumbnail-carousel__item-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  font-size: 1.6rem;
  text-align: center;
  color: #000;
  background: rgba(255, 255, 255, 0.7);
}

.c-thumbnail-carousel__thumbs-outer {
  margin: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-grow: 1;
  order: 2;
}

.c-thumbnail-carousel__thumbs-inner {
  overflow: hidden;
}

.c-thumbnail-carousel__thumbs {
  display: flex;
  list-style: none;
  margin: 10px auto;
  transition: transform .25s;
}

.c-thumbnail-carousel__thumb {
  width: 100px;
  height: 100px;
  margin: 0;
  opacity: .5;
  transition: opacity .25s;
  flex: 1 0 auto;
  cursor: pointer;
}

.c-thumbnail-carousel__thumb.is-active {
  opacity: 1;
}

.c-thumbnail-carousel__thumb-img {
  object-fit: cover;
  height: inherit;
  padding: 10px;
}

.c-thumbnail-carousel__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.c-thumbnail-carousel__prev,
.c-thumbnail-carousel__next {
  display: block;
  width: 40px;
  max-width: 40px;
  height: 40px;
  margin: 20px;
  cursor: pointer;
  flex-grow: 1;
}

.c-thumbnail-carousel__prev svg,
.c-thumbnail-carousel__next svg {
  height: inherit;
}

.c-thumbnail-carousel__prev {
  order: 1;
  width: 100px;
}

.c-thumbnail-carousel__next {
  order: 3;
  transform: rotate(180deg);
  width: 100px;
}

.c-tile {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .c-tile {
    width: calc(50% - 10px);
    padding-bottom: 0;
    height: 360px;
  }
}

@media (min-width: 768px) {
  .c-tile--large {
    height: 740px;
  }
}

.c-tile__bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-position: center center;
}

@media (min-width: 768px) {
  .c-tile__bg {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

.c-tile__content {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  width: 70%;
  height: 70%;
  max-width: 300px;
  max-height: 300px;
  transform: translate3d(-50%, -50%, 0);
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
}

@media (min-width: 768px) {
  .c-tile__content {
    width: 50%;
    height: 50%;
  }
}

.c-tile__content-img {
  margin: 0 auto;
  max-width: 100%;
  max-height: 100%;
}

.c-top {
  max-width: 900px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

@media (min-width: 375px) {
  .c-top {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
}

@media (min-width: 768px) {
  .c-top {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }
}

.c-top--small {
  max-width: 820px;
}

.c-top--home {
  margin-top: 0;
}

@media (min-width: 768px) {
  .c-top--home {
    margin-top: 4rem;
  }
}

.c-top__heading {
  font-size: 1.7rem;
  font-family: "Freight", serif;
  font-weight: 400;
  margin-bottom: .8em;
  letter-spacing: 1px;
  text-align: left;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .c-top__heading {
    font-size: 3rem;
    line-height: 1.5em;
    letter-spacing: 4px;
    text-align: center;
  }
}

.c-top__paragraph {
  max-width: 770px;
  margin-right: auto;
  margin-left: auto;
  letter-spacing: .5px;
  margin-bottom: 3em;
  text-align: left;
}

@media (min-width: 768px) {
  .c-top__paragraph {
    text-align: center;
  }
}

.c-top__paragraph--small {
  max-width: 690px;
}

.c-top__paragraph--no-buttons {
  margin-bottom: 0;
}

.c-top__button {
  margin: 0 0 1em;
  width: 100%;
}

@media (min-width: 375px) {
  .c-top__button {
    margin: 0 .5em 1em;
    width: auto;
  }
}

.js-slidein {
  opacity: 0;
  transform: translateY(35px);
  transition: opacity .7s .1s, transform .7s .1s;
  /* stylelint-disable-next-line */
}

html.no-js .js-slidein, .js-slidein.animate {
  opacity: 1;
  transform: translateY(0);
}

/* Hide visually but not from screen readers */
.u-hide {
  left: -9999em !important;
  position: absolute !important;
}

@media (min-width: 0) {
  .u-hide--bp-minimum {
    left: -9999em !important;
    position: absolute !important;
  }
}

@media (min-width: 0) and (max-width: 374px) {
  .u-hide--bp-minimum-only {
    left: -9999em !important;
    position: absolute !important;
  }
}

@media (min-width: 375px) {
  .u-hide--bp-small {
    left: -9999em !important;
    position: absolute !important;
  }
}

@media (min-width: 375px) and (max-width: 767px) {
  .u-hide--bp-small-only {
    left: -9999em !important;
    position: absolute !important;
  }
}

@media (min-width: 768px) {
  .u-hide--bp-medium {
    left: -9999em !important;
    position: absolute !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .u-hide--bp-medium-only {
    left: -9999em !important;
    position: absolute !important;
  }
}

@media (min-width: 1024px) {
  .u-hide--bp-large {
    left: -9999em !important;
    position: absolute !important;
  }
}

@media (min-width: 1024px) and (max-width: 1599px) {
  .u-hide--bp-large-only {
    left: -9999em !important;
    position: absolute !important;
  }
}

@media (min-width: 1600px) {
  .u-hide--bp-full {
    left: -9999em !important;
    position: absolute !important;
  }
}

@media (min-width: 1600px) and (max-width: 9999px) {
  .u-hide--bp-full-only {
    left: -9999em !important;
    position: absolute !important;
  }
}

.u-hide--real {
  display: none;
}

.u-spacer {
  margin-bottom: 1rem;
}

.u-noscroll {
  overflow: hidden;
}

.u-hide\@small {
  display: none;
}

@media (min-width: 768px) {
  .u-hide\@small {
    display: block;
  }
}

.u-no-margin {
  margin: 0;
}

@media (min-width: 0) {
  .u-no-margin--bp-minimum {
    margin: 0;
  }
}

@media (min-width: 0) and (max-width: 374px) {
  .u-no-margin--bp-minimum-only {
    margin: 0;
  }
}

@media (min-width: 375px) {
  .u-no-margin--bp-small {
    margin: 0;
  }
}

@media (min-width: 375px) and (max-width: 767px) {
  .u-no-margin--bp-small-only {
    margin: 0;
  }
}

@media (min-width: 768px) {
  .u-no-margin--bp-medium {
    margin: 0;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .u-no-margin--bp-medium-only {
    margin: 0;
  }
}

@media (min-width: 1024px) {
  .u-no-margin--bp-large {
    margin: 0;
  }
}

@media (min-width: 1024px) and (max-width: 1599px) {
  .u-no-margin--bp-large-only {
    margin: 0;
  }
}

@media (min-width: 1600px) {
  .u-no-margin--bp-full {
    margin: 0;
  }
}

@media (min-width: 1600px) and (max-width: 9999px) {
  .u-no-margin--bp-full-only {
    margin: 0;
  }
}

.u-no-padding {
  padding: 0;
}

@media (min-width: 0) {
  .u-no-padding--bp-minimum {
    padding: 0;
  }
}

@media (min-width: 0) and (max-width: 374px) {
  .u-no-padding--bp-minimum-only {
    padding: 0;
  }
}

@media (min-width: 375px) {
  .u-no-padding--bp-small {
    padding: 0;
  }
}

@media (min-width: 375px) and (max-width: 767px) {
  .u-no-padding--bp-small-only {
    padding: 0;
  }
}

@media (min-width: 768px) {
  .u-no-padding--bp-medium {
    padding: 0;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .u-no-padding--bp-medium-only {
    padding: 0;
  }
}

@media (min-width: 1024px) {
  .u-no-padding--bp-large {
    padding: 0;
  }
}

@media (min-width: 1024px) and (max-width: 1599px) {
  .u-no-padding--bp-large-only {
    padding: 0;
  }
}

@media (min-width: 1600px) {
  .u-no-padding--bp-full {
    padding: 0;
  }
}

@media (min-width: 1600px) and (max-width: 9999px) {
  .u-no-padding--bp-full-only {
    padding: 0;
  }
}

.u-color-black {
  color: #000;
}

.u-color-pale-blue {
  color: #7d99b3;
}

.u-relative {
  position: relative;
}

.u-bg-escape-container {
  position: relative;
}

.u-bg-escape-container::before {
  content: '';
  position: absolute;
  z-index: -1;
  width: 100vw;
  left: 50%;
  top: 0;
  bottom: 0;
  background: inherit;
  transform: translateX(-50%);
}

.u-fixed {
  position: fixed;
}

.u-margin-top-0 {
  margin-top: 0em;
}

@media (min-width: 0) {
  .u-margin-top-0--bp-minimum {
    margin-top: 0em;
  }
}

@media (min-width: 0) and (max-width: 374px) {
  .u-margin-top-0--bp-minimum-only {
    margin-top: 0em;
  }
}

@media (min-width: 375px) {
  .u-margin-top-0--bp-small {
    margin-top: 0em;
  }
}

@media (min-width: 375px) and (max-width: 767px) {
  .u-margin-top-0--bp-small-only {
    margin-top: 0em;
  }
}

@media (min-width: 768px) {
  .u-margin-top-0--bp-medium {
    margin-top: 0em;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .u-margin-top-0--bp-medium-only {
    margin-top: 0em;
  }
}

@media (min-width: 1024px) {
  .u-margin-top-0--bp-large {
    margin-top: 0em;
  }
}

@media (min-width: 1024px) and (max-width: 1599px) {
  .u-margin-top-0--bp-large-only {
    margin-top: 0em;
  }
}

@media (min-width: 1600px) {
  .u-margin-top-0--bp-full {
    margin-top: 0em;
  }
}

@media (min-width: 1600px) and (max-width: 9999px) {
  .u-margin-top-0--bp-full-only {
    margin-top: 0em;
  }
}

.u-margin-bottom-0 {
  margin-bottom: 0em;
}

@media (min-width: 0) {
  .u-margin-bottom-0--bp-minimum {
    margin-bottom: 0em;
  }
}

@media (min-width: 0) and (max-width: 374px) {
  .u-margin-bottom-0--bp-minimum-only {
    margin-bottom: 0em;
  }
}

@media (min-width: 375px) {
  .u-margin-bottom-0--bp-small {
    margin-bottom: 0em;
  }
}

@media (min-width: 375px) and (max-width: 767px) {
  .u-margin-bottom-0--bp-small-only {
    margin-bottom: 0em;
  }
}

@media (min-width: 768px) {
  .u-margin-bottom-0--bp-medium {
    margin-bottom: 0em;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .u-margin-bottom-0--bp-medium-only {
    margin-bottom: 0em;
  }
}

@media (min-width: 1024px) {
  .u-margin-bottom-0--bp-large {
    margin-bottom: 0em;
  }
}

@media (min-width: 1024px) and (max-width: 1599px) {
  .u-margin-bottom-0--bp-large-only {
    margin-bottom: 0em;
  }
}

@media (min-width: 1600px) {
  .u-margin-bottom-0--bp-full {
    margin-bottom: 0em;
  }
}

@media (min-width: 1600px) and (max-width: 9999px) {
  .u-margin-bottom-0--bp-full-only {
    margin-bottom: 0em;
  }
}

.u-margin-top-1 {
  margin-top: 1em;
}

@media (min-width: 0) {
  .u-margin-top-1--bp-minimum {
    margin-top: 1em;
  }
}

@media (min-width: 0) and (max-width: 374px) {
  .u-margin-top-1--bp-minimum-only {
    margin-top: 1em;
  }
}

@media (min-width: 375px) {
  .u-margin-top-1--bp-small {
    margin-top: 1em;
  }
}

@media (min-width: 375px) and (max-width: 767px) {
  .u-margin-top-1--bp-small-only {
    margin-top: 1em;
  }
}

@media (min-width: 768px) {
  .u-margin-top-1--bp-medium {
    margin-top: 1em;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .u-margin-top-1--bp-medium-only {
    margin-top: 1em;
  }
}

@media (min-width: 1024px) {
  .u-margin-top-1--bp-large {
    margin-top: 1em;
  }
}

@media (min-width: 1024px) and (max-width: 1599px) {
  .u-margin-top-1--bp-large-only {
    margin-top: 1em;
  }
}

@media (min-width: 1600px) {
  .u-margin-top-1--bp-full {
    margin-top: 1em;
  }
}

@media (min-width: 1600px) and (max-width: 9999px) {
  .u-margin-top-1--bp-full-only {
    margin-top: 1em;
  }
}

.u-margin-bottom-1 {
  margin-bottom: 1em;
}

@media (min-width: 0) {
  .u-margin-bottom-1--bp-minimum {
    margin-bottom: 1em;
  }
}

@media (min-width: 0) and (max-width: 374px) {
  .u-margin-bottom-1--bp-minimum-only {
    margin-bottom: 1em;
  }
}

@media (min-width: 375px) {
  .u-margin-bottom-1--bp-small {
    margin-bottom: 1em;
  }
}

@media (min-width: 375px) and (max-width: 767px) {
  .u-margin-bottom-1--bp-small-only {
    margin-bottom: 1em;
  }
}

@media (min-width: 768px) {
  .u-margin-bottom-1--bp-medium {
    margin-bottom: 1em;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .u-margin-bottom-1--bp-medium-only {
    margin-bottom: 1em;
  }
}

@media (min-width: 1024px) {
  .u-margin-bottom-1--bp-large {
    margin-bottom: 1em;
  }
}

@media (min-width: 1024px) and (max-width: 1599px) {
  .u-margin-bottom-1--bp-large-only {
    margin-bottom: 1em;
  }
}

@media (min-width: 1600px) {
  .u-margin-bottom-1--bp-full {
    margin-bottom: 1em;
  }
}

@media (min-width: 1600px) and (max-width: 9999px) {
  .u-margin-bottom-1--bp-full-only {
    margin-bottom: 1em;
  }
}

.u-margin-top-2 {
  margin-top: 2em;
}

@media (min-width: 0) {
  .u-margin-top-2--bp-minimum {
    margin-top: 2em;
  }
}

@media (min-width: 0) and (max-width: 374px) {
  .u-margin-top-2--bp-minimum-only {
    margin-top: 2em;
  }
}

@media (min-width: 375px) {
  .u-margin-top-2--bp-small {
    margin-top: 2em;
  }
}

@media (min-width: 375px) and (max-width: 767px) {
  .u-margin-top-2--bp-small-only {
    margin-top: 2em;
  }
}

@media (min-width: 768px) {
  .u-margin-top-2--bp-medium {
    margin-top: 2em;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .u-margin-top-2--bp-medium-only {
    margin-top: 2em;
  }
}

@media (min-width: 1024px) {
  .u-margin-top-2--bp-large {
    margin-top: 2em;
  }
}

@media (min-width: 1024px) and (max-width: 1599px) {
  .u-margin-top-2--bp-large-only {
    margin-top: 2em;
  }
}

@media (min-width: 1600px) {
  .u-margin-top-2--bp-full {
    margin-top: 2em;
  }
}

@media (min-width: 1600px) and (max-width: 9999px) {
  .u-margin-top-2--bp-full-only {
    margin-top: 2em;
  }
}

.u-margin-bottom-2 {
  margin-bottom: 2em;
}

@media (min-width: 0) {
  .u-margin-bottom-2--bp-minimum {
    margin-bottom: 2em;
  }
}

@media (min-width: 0) and (max-width: 374px) {
  .u-margin-bottom-2--bp-minimum-only {
    margin-bottom: 2em;
  }
}

@media (min-width: 375px) {
  .u-margin-bottom-2--bp-small {
    margin-bottom: 2em;
  }
}

@media (min-width: 375px) and (max-width: 767px) {
  .u-margin-bottom-2--bp-small-only {
    margin-bottom: 2em;
  }
}

@media (min-width: 768px) {
  .u-margin-bottom-2--bp-medium {
    margin-bottom: 2em;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .u-margin-bottom-2--bp-medium-only {
    margin-bottom: 2em;
  }
}

@media (min-width: 1024px) {
  .u-margin-bottom-2--bp-large {
    margin-bottom: 2em;
  }
}

@media (min-width: 1024px) and (max-width: 1599px) {
  .u-margin-bottom-2--bp-large-only {
    margin-bottom: 2em;
  }
}

@media (min-width: 1600px) {
  .u-margin-bottom-2--bp-full {
    margin-bottom: 2em;
  }
}

@media (min-width: 1600px) and (max-width: 9999px) {
  .u-margin-bottom-2--bp-full-only {
    margin-bottom: 2em;
  }
}

.u-margin-top-3 {
  margin-top: 3em;
}

@media (min-width: 0) {
  .u-margin-top-3--bp-minimum {
    margin-top: 3em;
  }
}

@media (min-width: 0) and (max-width: 374px) {
  .u-margin-top-3--bp-minimum-only {
    margin-top: 3em;
  }
}

@media (min-width: 375px) {
  .u-margin-top-3--bp-small {
    margin-top: 3em;
  }
}

@media (min-width: 375px) and (max-width: 767px) {
  .u-margin-top-3--bp-small-only {
    margin-top: 3em;
  }
}

@media (min-width: 768px) {
  .u-margin-top-3--bp-medium {
    margin-top: 3em;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .u-margin-top-3--bp-medium-only {
    margin-top: 3em;
  }
}

@media (min-width: 1024px) {
  .u-margin-top-3--bp-large {
    margin-top: 3em;
  }
}

@media (min-width: 1024px) and (max-width: 1599px) {
  .u-margin-top-3--bp-large-only {
    margin-top: 3em;
  }
}

@media (min-width: 1600px) {
  .u-margin-top-3--bp-full {
    margin-top: 3em;
  }
}

@media (min-width: 1600px) and (max-width: 9999px) {
  .u-margin-top-3--bp-full-only {
    margin-top: 3em;
  }
}

.u-margin-bottom-3 {
  margin-bottom: 3em;
}

@media (min-width: 0) {
  .u-margin-bottom-3--bp-minimum {
    margin-bottom: 3em;
  }
}

@media (min-width: 0) and (max-width: 374px) {
  .u-margin-bottom-3--bp-minimum-only {
    margin-bottom: 3em;
  }
}

@media (min-width: 375px) {
  .u-margin-bottom-3--bp-small {
    margin-bottom: 3em;
  }
}

@media (min-width: 375px) and (max-width: 767px) {
  .u-margin-bottom-3--bp-small-only {
    margin-bottom: 3em;
  }
}

@media (min-width: 768px) {
  .u-margin-bottom-3--bp-medium {
    margin-bottom: 3em;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .u-margin-bottom-3--bp-medium-only {
    margin-bottom: 3em;
  }
}

@media (min-width: 1024px) {
  .u-margin-bottom-3--bp-large {
    margin-bottom: 3em;
  }
}

@media (min-width: 1024px) and (max-width: 1599px) {
  .u-margin-bottom-3--bp-large-only {
    margin-bottom: 3em;
  }
}

@media (min-width: 1600px) {
  .u-margin-bottom-3--bp-full {
    margin-bottom: 3em;
  }
}

@media (min-width: 1600px) and (max-width: 9999px) {
  .u-margin-bottom-3--bp-full-only {
    margin-bottom: 3em;
  }
}

.u-margin-top-4 {
  margin-top: 4em;
}

@media (min-width: 0) {
  .u-margin-top-4--bp-minimum {
    margin-top: 4em;
  }
}

@media (min-width: 0) and (max-width: 374px) {
  .u-margin-top-4--bp-minimum-only {
    margin-top: 4em;
  }
}

@media (min-width: 375px) {
  .u-margin-top-4--bp-small {
    margin-top: 4em;
  }
}

@media (min-width: 375px) and (max-width: 767px) {
  .u-margin-top-4--bp-small-only {
    margin-top: 4em;
  }
}

@media (min-width: 768px) {
  .u-margin-top-4--bp-medium {
    margin-top: 4em;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .u-margin-top-4--bp-medium-only {
    margin-top: 4em;
  }
}

@media (min-width: 1024px) {
  .u-margin-top-4--bp-large {
    margin-top: 4em;
  }
}

@media (min-width: 1024px) and (max-width: 1599px) {
  .u-margin-top-4--bp-large-only {
    margin-top: 4em;
  }
}

@media (min-width: 1600px) {
  .u-margin-top-4--bp-full {
    margin-top: 4em;
  }
}

@media (min-width: 1600px) and (max-width: 9999px) {
  .u-margin-top-4--bp-full-only {
    margin-top: 4em;
  }
}

.u-margin-bottom-4 {
  margin-bottom: 4em;
}

@media (min-width: 0) {
  .u-margin-bottom-4--bp-minimum {
    margin-bottom: 4em;
  }
}

@media (min-width: 0) and (max-width: 374px) {
  .u-margin-bottom-4--bp-minimum-only {
    margin-bottom: 4em;
  }
}

@media (min-width: 375px) {
  .u-margin-bottom-4--bp-small {
    margin-bottom: 4em;
  }
}

@media (min-width: 375px) and (max-width: 767px) {
  .u-margin-bottom-4--bp-small-only {
    margin-bottom: 4em;
  }
}

@media (min-width: 768px) {
  .u-margin-bottom-4--bp-medium {
    margin-bottom: 4em;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .u-margin-bottom-4--bp-medium-only {
    margin-bottom: 4em;
  }
}

@media (min-width: 1024px) {
  .u-margin-bottom-4--bp-large {
    margin-bottom: 4em;
  }
}

@media (min-width: 1024px) and (max-width: 1599px) {
  .u-margin-bottom-4--bp-large-only {
    margin-bottom: 4em;
  }
}

@media (min-width: 1600px) {
  .u-margin-bottom-4--bp-full {
    margin-bottom: 4em;
  }
}

@media (min-width: 1600px) and (max-width: 9999px) {
  .u-margin-bottom-4--bp-full-only {
    margin-bottom: 4em;
  }
}

.u-margin-top-5 {
  margin-top: 5em;
}

@media (min-width: 0) {
  .u-margin-top-5--bp-minimum {
    margin-top: 5em;
  }
}

@media (min-width: 0) and (max-width: 374px) {
  .u-margin-top-5--bp-minimum-only {
    margin-top: 5em;
  }
}

@media (min-width: 375px) {
  .u-margin-top-5--bp-small {
    margin-top: 5em;
  }
}

@media (min-width: 375px) and (max-width: 767px) {
  .u-margin-top-5--bp-small-only {
    margin-top: 5em;
  }
}

@media (min-width: 768px) {
  .u-margin-top-5--bp-medium {
    margin-top: 5em;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .u-margin-top-5--bp-medium-only {
    margin-top: 5em;
  }
}

@media (min-width: 1024px) {
  .u-margin-top-5--bp-large {
    margin-top: 5em;
  }
}

@media (min-width: 1024px) and (max-width: 1599px) {
  .u-margin-top-5--bp-large-only {
    margin-top: 5em;
  }
}

@media (min-width: 1600px) {
  .u-margin-top-5--bp-full {
    margin-top: 5em;
  }
}

@media (min-width: 1600px) and (max-width: 9999px) {
  .u-margin-top-5--bp-full-only {
    margin-top: 5em;
  }
}

.u-margin-bottom-5 {
  margin-bottom: 5em;
}

@media (min-width: 0) {
  .u-margin-bottom-5--bp-minimum {
    margin-bottom: 5em;
  }
}

@media (min-width: 0) and (max-width: 374px) {
  .u-margin-bottom-5--bp-minimum-only {
    margin-bottom: 5em;
  }
}

@media (min-width: 375px) {
  .u-margin-bottom-5--bp-small {
    margin-bottom: 5em;
  }
}

@media (min-width: 375px) and (max-width: 767px) {
  .u-margin-bottom-5--bp-small-only {
    margin-bottom: 5em;
  }
}

@media (min-width: 768px) {
  .u-margin-bottom-5--bp-medium {
    margin-bottom: 5em;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .u-margin-bottom-5--bp-medium-only {
    margin-bottom: 5em;
  }
}

@media (min-width: 1024px) {
  .u-margin-bottom-5--bp-large {
    margin-bottom: 5em;
  }
}

@media (min-width: 1024px) and (max-width: 1599px) {
  .u-margin-bottom-5--bp-large-only {
    margin-bottom: 5em;
  }
}

@media (min-width: 1600px) {
  .u-margin-bottom-5--bp-full {
    margin-bottom: 5em;
  }
}

@media (min-width: 1600px) and (max-width: 9999px) {
  .u-margin-bottom-5--bp-full-only {
    margin-bottom: 5em;
  }
}

.u-margin-top-6 {
  margin-top: 6em;
}

@media (min-width: 0) {
  .u-margin-top-6--bp-minimum {
    margin-top: 6em;
  }
}

@media (min-width: 0) and (max-width: 374px) {
  .u-margin-top-6--bp-minimum-only {
    margin-top: 6em;
  }
}

@media (min-width: 375px) {
  .u-margin-top-6--bp-small {
    margin-top: 6em;
  }
}

@media (min-width: 375px) and (max-width: 767px) {
  .u-margin-top-6--bp-small-only {
    margin-top: 6em;
  }
}

@media (min-width: 768px) {
  .u-margin-top-6--bp-medium {
    margin-top: 6em;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .u-margin-top-6--bp-medium-only {
    margin-top: 6em;
  }
}

@media (min-width: 1024px) {
  .u-margin-top-6--bp-large {
    margin-top: 6em;
  }
}

@media (min-width: 1024px) and (max-width: 1599px) {
  .u-margin-top-6--bp-large-only {
    margin-top: 6em;
  }
}

@media (min-width: 1600px) {
  .u-margin-top-6--bp-full {
    margin-top: 6em;
  }
}

@media (min-width: 1600px) and (max-width: 9999px) {
  .u-margin-top-6--bp-full-only {
    margin-top: 6em;
  }
}

.u-margin-bottom-6 {
  margin-bottom: 6em;
}

@media (min-width: 0) {
  .u-margin-bottom-6--bp-minimum {
    margin-bottom: 6em;
  }
}

@media (min-width: 0) and (max-width: 374px) {
  .u-margin-bottom-6--bp-minimum-only {
    margin-bottom: 6em;
  }
}

@media (min-width: 375px) {
  .u-margin-bottom-6--bp-small {
    margin-bottom: 6em;
  }
}

@media (min-width: 375px) and (max-width: 767px) {
  .u-margin-bottom-6--bp-small-only {
    margin-bottom: 6em;
  }
}

@media (min-width: 768px) {
  .u-margin-bottom-6--bp-medium {
    margin-bottom: 6em;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .u-margin-bottom-6--bp-medium-only {
    margin-bottom: 6em;
  }
}

@media (min-width: 1024px) {
  .u-margin-bottom-6--bp-large {
    margin-bottom: 6em;
  }
}

@media (min-width: 1024px) and (max-width: 1599px) {
  .u-margin-bottom-6--bp-large-only {
    margin-bottom: 6em;
  }
}

@media (min-width: 1600px) {
  .u-margin-bottom-6--bp-full {
    margin-bottom: 6em;
  }
}

@media (min-width: 1600px) and (max-width: 9999px) {
  .u-margin-bottom-6--bp-full-only {
    margin-bottom: 6em;
  }
}

.u-margin-top-7 {
  margin-top: 7em;
}

@media (min-width: 0) {
  .u-margin-top-7--bp-minimum {
    margin-top: 7em;
  }
}

@media (min-width: 0) and (max-width: 374px) {
  .u-margin-top-7--bp-minimum-only {
    margin-top: 7em;
  }
}

@media (min-width: 375px) {
  .u-margin-top-7--bp-small {
    margin-top: 7em;
  }
}

@media (min-width: 375px) and (max-width: 767px) {
  .u-margin-top-7--bp-small-only {
    margin-top: 7em;
  }
}

@media (min-width: 768px) {
  .u-margin-top-7--bp-medium {
    margin-top: 7em;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .u-margin-top-7--bp-medium-only {
    margin-top: 7em;
  }
}

@media (min-width: 1024px) {
  .u-margin-top-7--bp-large {
    margin-top: 7em;
  }
}

@media (min-width: 1024px) and (max-width: 1599px) {
  .u-margin-top-7--bp-large-only {
    margin-top: 7em;
  }
}

@media (min-width: 1600px) {
  .u-margin-top-7--bp-full {
    margin-top: 7em;
  }
}

@media (min-width: 1600px) and (max-width: 9999px) {
  .u-margin-top-7--bp-full-only {
    margin-top: 7em;
  }
}

.u-margin-bottom-7 {
  margin-bottom: 7em;
}

@media (min-width: 0) {
  .u-margin-bottom-7--bp-minimum {
    margin-bottom: 7em;
  }
}

@media (min-width: 0) and (max-width: 374px) {
  .u-margin-bottom-7--bp-minimum-only {
    margin-bottom: 7em;
  }
}

@media (min-width: 375px) {
  .u-margin-bottom-7--bp-small {
    margin-bottom: 7em;
  }
}

@media (min-width: 375px) and (max-width: 767px) {
  .u-margin-bottom-7--bp-small-only {
    margin-bottom: 7em;
  }
}

@media (min-width: 768px) {
  .u-margin-bottom-7--bp-medium {
    margin-bottom: 7em;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .u-margin-bottom-7--bp-medium-only {
    margin-bottom: 7em;
  }
}

@media (min-width: 1024px) {
  .u-margin-bottom-7--bp-large {
    margin-bottom: 7em;
  }
}

@media (min-width: 1024px) and (max-width: 1599px) {
  .u-margin-bottom-7--bp-large-only {
    margin-bottom: 7em;
  }
}

@media (min-width: 1600px) {
  .u-margin-bottom-7--bp-full {
    margin-bottom: 7em;
  }
}

@media (min-width: 1600px) and (max-width: 9999px) {
  .u-margin-bottom-7--bp-full-only {
    margin-bottom: 7em;
  }
}

.u-margin-top-8 {
  margin-top: 8em;
}

@media (min-width: 0) {
  .u-margin-top-8--bp-minimum {
    margin-top: 8em;
  }
}

@media (min-width: 0) and (max-width: 374px) {
  .u-margin-top-8--bp-minimum-only {
    margin-top: 8em;
  }
}

@media (min-width: 375px) {
  .u-margin-top-8--bp-small {
    margin-top: 8em;
  }
}

@media (min-width: 375px) and (max-width: 767px) {
  .u-margin-top-8--bp-small-only {
    margin-top: 8em;
  }
}

@media (min-width: 768px) {
  .u-margin-top-8--bp-medium {
    margin-top: 8em;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .u-margin-top-8--bp-medium-only {
    margin-top: 8em;
  }
}

@media (min-width: 1024px) {
  .u-margin-top-8--bp-large {
    margin-top: 8em;
  }
}

@media (min-width: 1024px) and (max-width: 1599px) {
  .u-margin-top-8--bp-large-only {
    margin-top: 8em;
  }
}

@media (min-width: 1600px) {
  .u-margin-top-8--bp-full {
    margin-top: 8em;
  }
}

@media (min-width: 1600px) and (max-width: 9999px) {
  .u-margin-top-8--bp-full-only {
    margin-top: 8em;
  }
}

.u-margin-bottom-8 {
  margin-bottom: 8em;
}

@media (min-width: 0) {
  .u-margin-bottom-8--bp-minimum {
    margin-bottom: 8em;
  }
}

@media (min-width: 0) and (max-width: 374px) {
  .u-margin-bottom-8--bp-minimum-only {
    margin-bottom: 8em;
  }
}

@media (min-width: 375px) {
  .u-margin-bottom-8--bp-small {
    margin-bottom: 8em;
  }
}

@media (min-width: 375px) and (max-width: 767px) {
  .u-margin-bottom-8--bp-small-only {
    margin-bottom: 8em;
  }
}

@media (min-width: 768px) {
  .u-margin-bottom-8--bp-medium {
    margin-bottom: 8em;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .u-margin-bottom-8--bp-medium-only {
    margin-bottom: 8em;
  }
}

@media (min-width: 1024px) {
  .u-margin-bottom-8--bp-large {
    margin-bottom: 8em;
  }
}

@media (min-width: 1024px) and (max-width: 1599px) {
  .u-margin-bottom-8--bp-large-only {
    margin-bottom: 8em;
  }
}

@media (min-width: 1600px) {
  .u-margin-bottom-8--bp-full {
    margin-bottom: 8em;
  }
}

@media (min-width: 1600px) and (max-width: 9999px) {
  .u-margin-bottom-8--bp-full-only {
    margin-bottom: 8em;
  }
}

.u-margin-top-9 {
  margin-top: 9em;
}

@media (min-width: 0) {
  .u-margin-top-9--bp-minimum {
    margin-top: 9em;
  }
}

@media (min-width: 0) and (max-width: 374px) {
  .u-margin-top-9--bp-minimum-only {
    margin-top: 9em;
  }
}

@media (min-width: 375px) {
  .u-margin-top-9--bp-small {
    margin-top: 9em;
  }
}

@media (min-width: 375px) and (max-width: 767px) {
  .u-margin-top-9--bp-small-only {
    margin-top: 9em;
  }
}

@media (min-width: 768px) {
  .u-margin-top-9--bp-medium {
    margin-top: 9em;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .u-margin-top-9--bp-medium-only {
    margin-top: 9em;
  }
}

@media (min-width: 1024px) {
  .u-margin-top-9--bp-large {
    margin-top: 9em;
  }
}

@media (min-width: 1024px) and (max-width: 1599px) {
  .u-margin-top-9--bp-large-only {
    margin-top: 9em;
  }
}

@media (min-width: 1600px) {
  .u-margin-top-9--bp-full {
    margin-top: 9em;
  }
}

@media (min-width: 1600px) and (max-width: 9999px) {
  .u-margin-top-9--bp-full-only {
    margin-top: 9em;
  }
}

.u-margin-bottom-9 {
  margin-bottom: 9em;
}

@media (min-width: 0) {
  .u-margin-bottom-9--bp-minimum {
    margin-bottom: 9em;
  }
}

@media (min-width: 0) and (max-width: 374px) {
  .u-margin-bottom-9--bp-minimum-only {
    margin-bottom: 9em;
  }
}

@media (min-width: 375px) {
  .u-margin-bottom-9--bp-small {
    margin-bottom: 9em;
  }
}

@media (min-width: 375px) and (max-width: 767px) {
  .u-margin-bottom-9--bp-small-only {
    margin-bottom: 9em;
  }
}

@media (min-width: 768px) {
  .u-margin-bottom-9--bp-medium {
    margin-bottom: 9em;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .u-margin-bottom-9--bp-medium-only {
    margin-bottom: 9em;
  }
}

@media (min-width: 1024px) {
  .u-margin-bottom-9--bp-large {
    margin-bottom: 9em;
  }
}

@media (min-width: 1024px) and (max-width: 1599px) {
  .u-margin-bottom-9--bp-large-only {
    margin-bottom: 9em;
  }
}

@media (min-width: 1600px) {
  .u-margin-bottom-9--bp-full {
    margin-bottom: 9em;
  }
}

@media (min-width: 1600px) and (max-width: 9999px) {
  .u-margin-bottom-9--bp-full-only {
    margin-bottom: 9em;
  }
}

.u-margin-top-10 {
  margin-top: 10em;
}

@media (min-width: 0) {
  .u-margin-top-10--bp-minimum {
    margin-top: 10em;
  }
}

@media (min-width: 0) and (max-width: 374px) {
  .u-margin-top-10--bp-minimum-only {
    margin-top: 10em;
  }
}

@media (min-width: 375px) {
  .u-margin-top-10--bp-small {
    margin-top: 10em;
  }
}

@media (min-width: 375px) and (max-width: 767px) {
  .u-margin-top-10--bp-small-only {
    margin-top: 10em;
  }
}

@media (min-width: 768px) {
  .u-margin-top-10--bp-medium {
    margin-top: 10em;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .u-margin-top-10--bp-medium-only {
    margin-top: 10em;
  }
}

@media (min-width: 1024px) {
  .u-margin-top-10--bp-large {
    margin-top: 10em;
  }
}

@media (min-width: 1024px) and (max-width: 1599px) {
  .u-margin-top-10--bp-large-only {
    margin-top: 10em;
  }
}

@media (min-width: 1600px) {
  .u-margin-top-10--bp-full {
    margin-top: 10em;
  }
}

@media (min-width: 1600px) and (max-width: 9999px) {
  .u-margin-top-10--bp-full-only {
    margin-top: 10em;
  }
}

.u-margin-bottom-10 {
  margin-bottom: 10em;
}

@media (min-width: 0) {
  .u-margin-bottom-10--bp-minimum {
    margin-bottom: 10em;
  }
}

@media (min-width: 0) and (max-width: 374px) {
  .u-margin-bottom-10--bp-minimum-only {
    margin-bottom: 10em;
  }
}

@media (min-width: 375px) {
  .u-margin-bottom-10--bp-small {
    margin-bottom: 10em;
  }
}

@media (min-width: 375px) and (max-width: 767px) {
  .u-margin-bottom-10--bp-small-only {
    margin-bottom: 10em;
  }
}

@media (min-width: 768px) {
  .u-margin-bottom-10--bp-medium {
    margin-bottom: 10em;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .u-margin-bottom-10--bp-medium-only {
    margin-bottom: 10em;
  }
}

@media (min-width: 1024px) {
  .u-margin-bottom-10--bp-large {
    margin-bottom: 10em;
  }
}

@media (min-width: 1024px) and (max-width: 1599px) {
  .u-margin-bottom-10--bp-large-only {
    margin-bottom: 10em;
  }
}

@media (min-width: 1600px) {
  .u-margin-bottom-10--bp-full {
    margin-bottom: 10em;
  }
}

@media (min-width: 1600px) and (max-width: 9999px) {
  .u-margin-bottom-10--bp-full-only {
    margin-bottom: 10em;
  }
}
