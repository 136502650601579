.c-team-members__top {
  margin-bottom: 5rem;
  @include bp-medium {
    margin-bottom: 10rem;
  }
}

.c-team-members__founders {
  margin-bottom: 7rem;
  @include bp-medium {
    margin-bottom: 14rem;
    padding-bottom: 56%;
    position: relative;
  }
}

.c-team-members__founders-container {
  @include bp-medium {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.c-team-members__grids {
  max-width: 1400px;
}
