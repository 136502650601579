.c-about__top {
  margin-bottom: 4rem;
  @include bp-medium {
    margin-bottom: 7rem;
  }
}

.c-about__mid {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
}

.c-about__bottom {
  @include clearfix;
  background-color: $color-lightest-gray;
  padding-top: 7rem;
  padding-bottom: 7rem;
  border-top: 1px solid rgba($color-pale-blue, .7);
  @include bp-medium {
    margin-top: 12rem;
    padding-top: 14rem;
    padding-bottom: 14rem;
    border-top: 0;
  }
}

.c-about__bottom-container {
  @include clearfix;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;

  @include bp-medium {
    flex-direction: row;
  }
}

.c-about__bottom-column {
  flex: 0 0 auto;
  width: 100%;

  @include bp-medium {
    width: 50%;
  }
}

.c-about__bottom-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 3em;

  @include bp-medium {
    margin-bottom: 0;
  }
}

.c-about__bottom-heading {
  font-family: $font-freight;
  font-size: 3.2rem;
}

.c-about__bottom-paragraph {
  line-height: 2;
  margin-bottom: 1em;

  &:last-of-type {
    margin-bottom: 2em;
  }
}

.c-about__bottom-right {
  @include shadow(45px, 5px);
  @include bp-medium {
    padding-left: 10rem;
  }
}

.c-about__bottom-link {
  width: 100%;
  text-align: center;
  @include bp-small {
    width: auto;
  }
}

.c-about__bottom-image {
  display: block;
  width: 100%;
  height: auto;
}
