.c-footer {
  margin-top: 7.5rem;
  margin-bottom: 4rem;
  font-size: 1.3rem;

  @include bp-medium {
    display: flex;
    width: 100%;
  }
}

.c-footer__contact {
  @include bp-medium {
    padding-right: 14rem;
  }
}

.c-footer__contact-title {
  font-size: 1.3rem;
  font-weight: 600;
  font-family: $font-din;
  margin-bottom: 1.5em;
}

.c-footer__contact-item {
  display: block;
  text-decoration: none;
  margin: 0;
  line-height: 2;

  &--address-line-2 {
    margin-bottom: 1.5em;
  }
}

.c-footer__list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 2rem 0 1rem;

  @include bp-medium {
    margin-left: 0;
    margin-right: 0;
  }
}

.c-footer__list-item {
  margin: 0;
  font-weight: bold;
  text-transform: uppercase;

  > a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &:first-child {
    .c-footer__list-link {
      padding-left: 0;
    }
  }
}

.c-footer__list-link {
  display: block;
  padding: .5rem 1rem;
  font-weight: 600;
}

.c-footer__bottom {
  @include bp-medium {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.c-footer__content {
  flex: 1;
}

.c-footer__newsletter-form {
  margin: 3rem 0;
  display: block;
}

.c-footer__copyright {
  padding: .5rem 0;
  color: $color-grey;
}

.c-footer__warning {
  border-top: 1px solid #ECEFF7;
  padding-top: 18px;
  p {
      display: block;
      width: 100%;
      max-width: 890px;
      a{
        display: unset;
      }
  }
}
