.c-sidebar__section {
  margin-bottom: 2em;

  &--with-border {
    border-bottom: 1px solid $color-lighter-gray;
  }

  p {
    margin: 0;
  }
}

.c-sidebar__section-header {
  text-transform: uppercase;
  font-size: 1em;
  font-weight: 600;
  letter-spacing: 1px;
}

.c-sidebar__subscribe {
  display: block;
  text-align: center;
}

.c-sidebar__visit {
  display: block;
  text-align: center;
  margin: 2em 0 3em;
}

.c-sidebar__link {
  text-decoration: none;
  color: $color-pale-blue;
}

.c-sidebar__select {
  width: 100%;
  border: 0;
  padding: 0;
}

// Based on .c-filter-bar__search

.c-sidebar__search {
  position: relative;
  width: 100%;
  height: 40px;
}

.c-sidebar__search-input {
  position: relative;
  top: -3px;
  width: calc(100% - 41px);
  padding: 0;
  border: 0;
  letter-spacing: 1px;
  -webkit-font-smoothing: subpixel-antialiased;

  &::placeholder {
    color: $color-text;
    text-transform: uppercase;
    font-weight: 600;
    opacity: 1;
  }
}

.c-sidebar__search-button {
  @include btn-reset;
  width: 17px;
  height: 17px;
  cursor: pointer;
  position: relative;
  top: 3px;
}

.c-sidebar__search-icon {
  width: 100%;
  height: 100%;
}
