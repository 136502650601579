.c-photo-carousel__header {
  margin: 5rem 0;
  font-family: $font-freight;
  font-weight: 400;
  font-size: 3.2rem;
  letter-spacing: .5px;
}

.c-carousel__photo {
  @include shadow(20px, 0);

  &:after {
    opacity: .7;
  }
}
