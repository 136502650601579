.o-btn {
  display: block;
  width: 100%;
  appearance: none;
  background: none;
  padding: 1.8rem 2rem;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 3rem;
  border: 1px solid;
  color: inherit;
  cursor: pointer;
  letter-spacing: 1px;
  transition: all .25s;

  @include bp-medium {
    display: inline-block;
    width: auto;
    padding: 1.6rem 3.1rem;
  }

  &--pale-blue {
    border-color: $color-pale-blue;
    color: $color-pale-blue;

    &:hover {
      background-color: $color-pale-blue;
      color: $color-white;
    }
  }
}

.o-lnk {
  @extend .o-btn;

  @include bp-medium {
    padding-left: 0;
    padding-right: 0;
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-radius: 0;
  }
}
