.c-filter-bar {
  width: 100%;
  display: flex;
  flex-direction: column;

  @include bp-medium {
    flex-direction: row;
  }
}

.c-filter-bar__column {
  display: flex;
  align-items: center;
  text-transform: uppercase;

  @include bp-medium {
    border: 0;
    border-left: 1px solid $color-lighter-gray;
    padding: 0 30px;
    margin-top: 0;
  }

  &:first-child {
    padding-left: 0;
    border-left: none;
    margin-top: 0;
  }

  &--horizontal {
    display: none;
    @include bp-medium {
      display: flex;
    }
  }
}

.c-filter-bar__select-icon {
  @include bp-medium {
    right: 0;
  }
}

.c-filter-bar__select {
  @include bp-medium {
    padding: 0;
    border: 0;
    width: 150px;
  }
}

.c-filter-bar__label {
  color: $color-grey;
}

.c-filter-bar__spacer {
  flex: 1 1;
  padding: 0;
}

.c-filter-bar__search {
  position: relative;
  width: 100%;
}

.c-filter-bar__search-input {
  position: relative;
  top: -3px;
  width: calc(100% - 21px);
  padding: 0;
  border: 0;
  letter-spacing: 1px;
  -webkit-font-smoothing: subpixel-antialiased;
  font-weight: 600;

  @include bp-medium {
    width: 100px;
  }

  @include bp-large {
    width: 250px;
  }

  &::placeholder {
    color: $color-text;
    text-transform: uppercase;
    font-weight: 600;
    opacity: 1;
  }
}

.c-filter-bar__search-button {
  @include btn-reset;
  width: 17px;
  height: 17px;
  cursor: pointer;
  position: relative;
  top: 3px;
}

.c-filter-bar__search-icon {
  width: 100%;
  height: 100%;
}
