.c-hero {
  position: relative;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  color: inherit;
}

.c-hero__lory-frame {
  min-height: 100vh;
}

.c-hero__lory-container {
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.c-hero__lory-slide {
  position: relative;
  min-height: 100vh;
}

.c-hero__lory-prev,
.c-hero__lory-next {
  transform: none;
  top: auto;
  bottom: 0;
}

.c-hero__bg {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.c-hero__bg-image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: .7;

  @include bp-medium {
    opacity: 1;
  }
}

.c-hero__container {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100vw;
  min-height: inherit;
}

.c-hero__content {
  max-width: 600px;

  &--centered {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
    text-align: center;
  }
}

.c-hero__overlay-image {
  display: block;
  margin: auto;

  ~ * {
    display: none;
  }

  @include bp-medium {
    position: absolute;
    left: 20px;
    top: 20px;
  }
}

.c-hero__central-image {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 364px;
  max-width: 100%;
  margin-bottom: 30px;
}

.c-hero__subtitle {
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
}

.c-hero__title {
  font-family: $font-freight;
  font-size: 2.6rem;
  letter-spacing: .5px;
  font-weight: 300;

  @include bp-medium {
    font-size: 3.2rem;
  }
}

.c-hero__subtitle,
.c-hero__title,
.c-hero__btn {
  &--inverted-color {
    color: $color-white;

    &:hover {
      color: $color-white;
    }
  }
}

.c-hero__btn {
  &:hover {
    opacity: .6;
  }

  &--inverted-color {
    &:hover {
      color: $color-lighter-gray;
      opacity: 1;
    }
  }
}

.c-hero__back-link {
  display: block;
  position: relative;
  width: 200px;
  height: 8px;
  color: $color-white;
  cursor: pointer;

  > svg {
    display: block;
    position: absolute;
    top: 50%;
    height: 40px;
    width: 50px;
    transform: translateY(-50%);
  }

  &:hover {
    color: $color-white;
  }

  &--not-clickable {
    cursor: default;
  }
}

.c-hero__forward-link {
  position: relative;
  padding: 5px 25px 5px 5px;
  color: $color-white;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  width: 200px;
  text-align: right;
  font-weight: bold;

  &:hover {
    color: $color-white;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: 50%;
    width: 0;
    height: 0;
    border-left: 8px solid $color-white;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    transform: translateY(-50%);
  }
}

.c-hero__scroll-icon {
  position: absolute;
  left: 50%;
  bottom: 2.5%;
  width: 50px;
  height: 40px;
  color: $color-white;
  transform: translateX(-50%);
  cursor: pointer;

  @include bp-medium {
    bottom: 7.5%;
    width: 80px;
  }

  > svg {
    display: block;
    width: inherit;
    height: inherit;
  }
}
