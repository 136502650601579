// Mixins

/* Clearfix */
@mixin clearfix {
  &:after {
    clear: both;
    content: '';
    display: block;
  }
}

@mixin bp-minimal-only {
  @media (max-width: ($bp-small - 1px)) {
    @content;
  }
}

/* 375px */
@mixin bp-small {
  @media (min-width: $bp-small) {
    @content;
  }
}

@mixin bp-small-only {
  @media (min-width: $bp-small) and (max-width: ($bp-medium - 1px)) {
    @content;
  }
}

/* 768px */
@mixin bp-medium {
  @media (min-width: $bp-medium) {
    @content;
  }
}

@mixin bp-medium-only {
  @media (min-width: $bp-medium) and ((max-width: $bp-large - 1px)) {
    @content;
  }
}

/* 1024px */
@mixin bp-large {
  @media (min-width: $bp-large) {
    @content;
  }
}

@mixin bp-large-only {
  @media (min-width: $bp-large) and ((max-width: $bp-full - 1px)) {
    @content;
  }
}

/* 1600px */
@mixin bp-full {
  @media (min-width: $bp-full) {
    @content;
  }
}

/* Custom */
@mixin bp($bp) {
  @media (min-width: $bp) {
    @content;
  }
}

@mixin btn-reset {
  cursor: pointer;
  appearance: none;
  background: none;
  padding: 0;
  border: 0;
  color: inherit;
}

@mixin absolute-fullscreen {
  position: absolute;
  z-index: $z-base;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

@mixin shadow($height, $margin) {
  &:after {
    // Based on: c-covers-carousel__photo
    content: '';
    display: block;
    width: 100%;
    height: $height;
    background: radial-gradient(ellipse at center, rgba(#0b1f2e, .2) 0%, rgba(0, 0, 0, 0) 65%);
    margin-bottom: $margin;
  }
}

@mixin breakpointize($includeContentWithoutMQ: false) {
  @if $includeContentWithoutMQ {
    @content;
  }

  @each $label, $map in $breakpoints {
    &--#{$label} {
      @media (min-width: map-get($map, min)) {
        @content;
      }
    }

    &--#{$label}-only {
      @media (min-width: map-get($map, min)) and (max-width: map-get($map, max)) {
        @content;
      }
    }
  }
}
