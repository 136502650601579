.c-top {
  max-width: 900px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;

  @include bp-small {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  @include bp-medium {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }

  &--small {
    max-width: 820px;
  }

  &--home {
    margin-top: 0;
    @include bp-medium {
      margin-top: 4rem;
    }
  }
}

.c-top__heading {
  font-size: 1.7rem;
  font-family: $font-freight;
  font-weight: 400;
  margin-bottom: .8em;
  letter-spacing: 1px;
  text-align: left;
  text-transform: uppercase;

  @include bp-medium {
    font-size: 3rem;
    line-height: 1.5em;
    letter-spacing: 4px;
    text-align: center;
  }
}

.c-top__paragraph {
  max-width: 770px;
  margin-right: auto;
  margin-left: auto;
  letter-spacing: .5px;
  margin-bottom: 3em;
  text-align: left;

  @include bp-medium {
    text-align: center;
  }

  &--small {
    max-width: 690px;
  }

  &--no-buttons {
    margin-bottom: 0;
  }
}

.c-top__button {
  margin: 0 0 1em;
  width: 100%;
  @include bp-small {
    margin: 0 .5em 1em;
    width: auto;
  }
}
